/*
 *  Agent - Chat Component
 *  ---------
 *  Checkout module
 */

// React imports
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

// node.js library that concatenates classes (strings)
import classnames from "classnames";

// API (call for results)
import axios from 'axios';

// Import config
import config from './../../config';

// Auth actions
import {
  INITIALIZE_AGENT,
  ADD_CHAT_MESSAGE,
  SET_PROCESSING
} from 'store/actions';

class ChatInput extends React.Component {
  // UI state
  state = {
    query: ""
  }

  // Constructor
  constructor(props) {
    super(props);
  }

  // Mount
  componentDidMount() {
    // Check for initial query
    if(this.props.agent && this.props.agent.query !== "") {
      console.log(" --> Initial Query: ", this.props.agent.query);

      // Send payload
      this.send(this.props.agent.query, this.props.agent.messages, this.props.dispatch);

      // Clear query (state)
      this.setState({
        query: ""
      });
    }
  }

  // On sbumit
  send(query, messages, dispatch) {
    // Get text query
    console.log(" - Submitting query: ", query, messages);

    // Fetch and stream response
    const _this = this;
    const fetchResponse = async (query) => {
      console.log(" --> Streaming Reponse: ", query);

      // Get response
      const response = await fetch(`${config.persona.chat.url}/api/generate`, {
        method: "POST",
        headers: {
          Accept: 'application/json',
          "Content-Type": "application/json",
        },
        body: JSON.stringify(query)
      });

      // Get reader
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let done = false;
      let buffer = "";
      let chunkNumber = 0;
      while (!done) {
        const { value, done: doneReading } = await reader.read();
        done = doneReading;
        const chunkValue = decoder.decode(value);

        // console.log(`\n[${chunkNumber}] ===`);
        // console.log("- Current Buffer: ", buffer);
        // console.log("- Raw Message: ", chunkValue);
        // console.log("\n");

        // Break by lines and process each line
        const lines = chunkValue.split("\n");
        //console.log("Total Lines: ", lines.length);

        for(var i = 0; i < lines.length; i++) {
          //console.log(` - Line [${i}]: ${lines[i]}`);

          // Add to buffer
          buffer += lines[i];

          try {
            // Parse buffer
            let newMessages = JSON.parse(buffer);
            console.log(" --> Parsed Messages: ", newMessages);

            // Parse messages and update session
            if(newMessages && newMessages.length > 0) {
              // Iterate over messages and update
              for(var i = 0; i < newMessages.length; i++) {
                // Update query
                dispatch({
                  type: ADD_CHAT_MESSAGE,
                  payload: {
                    id: newMessages[i].id,
                    type: newMessages[i].type,
                    role: newMessages[i].role,
                    data: newMessages[i].data,
                    content: newMessages[i].content
                  }
                });
              }
            }

            // Clear buffer
            buffer = "";
          } catch (err) {
            // Do nothing
          }
        }

        // Set processing to complete
        dispatch({
          type: SET_PROCESSING,
          payload: {
            status: false
          }
        });

        // Increment chunk count
        chunkNumber++;
      }
    };

    // Set processing flag
    dispatch({
      type: SET_PROCESSING,
      payload: {
        status: true
      }
    });

    // Send query
    dispatch({
      type: ADD_CHAT_MESSAGE,
      payload: {
        id: `msg-00000-${messages.length.toString().padStart(4, '0')}-000`,
        type: "query",
        role: "user",
        data: {
          display: true
        },
        content: query
      }
    });

    // Construct payload
    const data = {
      userId: "abc",
      persona: this.props.agent.persona,
      messages: messages.slice(1)
    };
    console.log("Query: ", data);

    // Query service
    (async () => {
      // Complete fetching results
      await fetchResponse(data);

      // // Set agent data loaded
      // _this.props.dispatch('chat/agent/loaded', {
      //   status: true
      // });
    })();
  }

  render() {
    // Passed in props
    const {
      profile,
      account,
      agent,
      dispatch
    } = this.props;

    // UI interactions
    const _this = this;
    const handleChange = (event) => {
      // update state
      _this.setState({
        query: event.target.value
      });
    };

    // Enter button
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        // Grab query
        const query = _this.state.query;

        // Send payload
        _this.send(query, agent.messages, dispatch);

        // Clear query (state)
        _this.setState({
          query: ""
        });

        // Clea query (input)
        event.target.value = "";
      }
    };

    // Submit button
    const handleSubmit = (event) => {
      // Grab query
      const query = _this.state.query;

      // Send payload
      _this.send(query, agent.messages, dispatch);

      // Clear query (state)
      _this.setState({
        query: ""
      });

      // Clea query (input)
      event.target.value = "";
    }

    return (
      <div className="card-footer d-block">
        <div className="input-group mb-3">
            <input
              onKeyDown={handleKeyDown} onChange={handleChange}
              type="text"
              placeholder="Ask a question about your data..."
              className="form-control"
              aria-label="Amount (to the nearest dollar)">
            </input>
            <div onClick={handleSubmit} className="input-group-append">
                <span className="input-group-text bg-primary">
                    <i className="fa fa-paper-plane"></i>
                </span>
            </div>
        </div>
      </div>
    )
  }
}

// Connect to store
const ConnectedChatInput = connect(state => ({
  profile: state.profile,
  account: state.account,
  agent: state.agent
}))(ChatInput);

export default ConnectedChatInput;
