/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/

// React
import React, { Component } from 'react';
import { connect } from 'react-redux';

// nodejs library to set properties for components
import PropTypes from "prop-types";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Form,
  FormGroup,
  Label,
  Input,
  ListGroupItem,
  ListGroup,
  Media,
  NavItem,
  NavLink,
  Nav,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Spinner
} from "reactstrap";

// Account config
import config from "config";

// CubeJs
import cubejs from '@cubejs-client/core';
import { QueryRenderer } from '@cubejs-client/react';

// Pre-ready queries
function generateQuery(report, filters) {
  return {
    "order": [
      [
        "Members.createdDate",
        "desc"
      ]
    ],
    "dimensions": [
      "Members.firstName",
      "Members.lastName",
      "Members.city",
      "Members.state",
      "Members.createdDate"
    ],
    "timeDimensions": [
      {
        "dimension": "Members.createdAt",
        "granularity": "day",
        "dateRange": "Last 7 days"
      }
    ]
  };
}

function render() {
  // Wrapper for query for all data
  function renderFromQuery({ resultSet, error }) {
    console.log("-> Results: ", resultSet);

    // Format data
    let data = [];

    // Parse result set
    if(resultSet && resultSet.loadResponse.results.length > 0) {
      // Get first row results
      const results = resultSet.loadResponse.results[0].data;

      // Populate data
      for(var i = 0; i < results.length; i++) {
        // Add to data
        data.push({
          name: `${results[i]['Members.firstName']} ${results[i]['Members.lastName']}`,
          city: results[i]['Members.city'],
          state: results[i]['Members.state'],
          dateJoined: results[i]['Members.createdDate']
        })
      }
    }
    console.log("Data: ", data);

    // Return formatted table
    return (
      <>
        { data.length == 0 ?
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          :
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="12">
                      <Card>
                        <CardHeader className="border-0">
                          <Row>
                            <Col xs="8">
                              <h3 className="mb-0">New Members</h3>
                            </Col>
                            <Col className="text-right" xs="4">
                            </Col>
                          </Row>
                        </CardHeader>
                        <Table className="align-items-center table-flush" responsive striped>
                          <thead className="thead-light">
                            <tr>
                              <th className="sort" data-sort="name" scope="col">
                                Name
                              </th>
                              <th className="sort" data-sort="city" scope="col">
                                City
                              </th>
                              <th className="sort" data-sort="state" scope="col">
                                State
                              </th>
                              <th className="sort" data-sort="date_joined" scope="col">
                                Date Joined
                              </th>
                            </tr>
                          </thead>
                          <tbody className="list">
                            { data.map((member, idx) => (
                              <tr key={idx}>
                                <td className="name">{member.name}</td>
                                <td className="text-center city">{member.city}</td>
                                <td className="text-center state">{member.state}</td>
                                <td className="text-center date_joined">{member.dateJoined.slice(0, 10)}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        }
      </>
    );
  }

  // Return rener query
  return renderFromQuery;
}

// Report Table Component
class MembersList extends Component {

  // On mount
  constructor(props) {
    super(props);
  }

  // Render
  render() {
    // Get props
    const { filters, index, cubejsApi } = this.props;
    console.log("Members List: ", this.props);

    // result
    return (
      <QueryRenderer query={generateQuery(filters)}
                     cubejsApi={cubejsApi}
                     filters={filters}
                     render={render()} />
    )
  }
}

// Connect to store
const ConnectedMembersList = connect(state => ({
  profile: state.profile,
  filters: state.report.filters
}))(MembersList);

export default ConnectedMembersList;
