/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect, useState } from "react";

// react library for routing
import { Link, useHistory } from "react-router-dom";
import { connect } from 'react-redux';

// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// Config
import config from "config";

// Firebase Auth
import { auth, firestore, googleAuthProvider } from "./../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

// core components
import AuthHeader from "components/Headers/AuthHeader.js";

// Auth actions
import {
  INITIALIZE_PROFILE
} from 'store/actions';

function Login({ profile, dispatch }) {
  // States
  const [user, loading, error] = useAuthState(auth);

  // States
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [focusedPassword, setfocusedPassword] = React.useState(false);

  // Navigation
  const history = useHistory();

  // Get profile
  useEffect(() => {
    if (loading) return;
    if (user) {
      console.log("User Loaded: ", user);

      // Determine provider
      let provider = (user.providerData.length > 0) ? user.providerData[0].providerId : "password";

      // Load profile
      loadProfile(user, provider);
    } else {
      // Send back to login
      history.push("/auth/login");
    }
  }, [user, loading]);

  /*
   *  [1] Google Authentication
   */
  const signInWithGoogle = async () => {
   try {
     // Process response
     const res = await auth.signInWithPopup(googleAuthProvider);
     const credential = res.credential;
     const token = res.token;
     const user = res.user;

     // Load user
     if(user) {
       loadProfile(user, "google");
     }

   } catch (err) {
     console.error(err);
     alert(err.message);
   }
  };

  /*
   *  [2] Email Authentication
   */
  const logInWithEmailAndPassword = async (email, password) => {
    try {
      // Authenticate
      const userCredential = await auth.signInWithEmailAndPassword(email, password);
      console.log(userCredential);

      // Load user
      if(userCredential.user) {
        loadProfile(userCredential.user, "password");
      }

    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  // Lookup and load profile
  const loadProfile = async (user, provider) => {
    // Find profile
    console.log(user);

    const profilesRef = firestore.collection("profiles");
    const profile = await profilesRef.doc(user.uid).get();
    if(profile.exists) {
      // Parse profile
      const profileData = profile.data();
      console.log("Profile Exists: ", profileData);

      // Initialize profile
      dispatch({
        type: INITIALIZE_PROFILE,
        payload: profileData
      });

      // Send to home
      history.push("/admin/home");
    } else {
      console.log("Profile does not exist.");

      // Create initial profile data
      const profileData = {
        uid: user.uid,
        name: user.displayName,
        authProvider: provider,
        email: user.email,
        complete: true,
        details: {
          firstName: (user.displayName && user.displayName.split(" ").length > 1) ? user.displayName.split(" ")[0] : null,
          lastName: null
        },
        account: {
          role: "CLIENT",
          imageUrl: config.account.imageUrl,
          membership: [ config.account.id ]
        }
      };

      // Create new profile with authentication
      const newProfile = await profilesRef.doc(user.uid).set(profileData);

      // Initialize profile
      dispatch({
        type: INITIALIZE_PROFILE,
        payload: profileData
      });

      // Send to home
      history.push("/admin/home");
    }
  }

  // Track submit on form
  const handleKeyDown = async (event) => {
    if (event.key === 'Enter') {
      // Trigger login
      logInWithEmailAndPassword(email, password);
    }
  }

  return (
    <>
      <AuthHeader />
      <Container className="mt--9 pb-5">
        <Row className="justify-content-center">
          <Col lg="8" md="10">
            <Card className="bg-white border-0 mb-0">
              <CardHeader className="bg-transparent pb-5 px-lg-5 py-lg-5">
                <div className="btn-wrapper text-center">
                  <Button
                    className="btn-neutral btn-icon"
                    color="info"
                    href=""
                    onClick={signInWithGoogle}
                    block
                  >
                    <span className="btn-inner--icon mr-1">
                      <img
                        alt="..."
                        src={
                          require("assets/img/icons/common/google.svg").default
                        }
                      />
                    </span>
                    <span className="btn-inner--text text-info">Google</span>
                  </Button>
                </div>
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                <Form role="form">
                  <FormGroup
                    className={classnames("mb-3", {
                      focused: focusedEmail,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onFocus={() => setfocusedEmail(true)}
                        onBlur={() => setfocusedEmail(true)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedPassword,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onFocus={() => setfocusedPassword(true)}
                        onBlur={() => setfocusedPassword(true)}
                        onKeyDown={handleKeyDown}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <Button
                      className="my-4" color="info"
                      type="button" block
                      onClick={() => logInWithEmailAndPassword(email, password)}
                    >
                      Sign In
                    </Button>
                  </div>
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id=" customCheckLogin"
                      type="checkbox"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor=" customCheckLogin"
                    >
                      <span className="text-muted">Remember me</span>
                    </label>
                  </div>
                </Form>
              </CardBody>
            </Card>
            <Row className="mt-3">
              <Col xs="6">
                <a
                  className="text-light"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <small>Forgot password?</small>
                </a>
              </Col>
              <Col className="text-right" xs="6">
                <a
                  className="text-light"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <small>Create new account</small>
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

// Connect to store
const ConnectedLogin = connect(state => ({
  profile: state.profile
}))(Login);

export default ConnectedLogin;
