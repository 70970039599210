/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';

// node.js library that concatenates classes (strings)
import classnames from "classnames";

// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";

// react plugin used to create DropdownMenu for selecting items
import Select2 from "react-select2-wrapper";

// Sparklines
import { Sparklines, SparklinesLine, SparklinesBars, SparklinesSpots, SparklinesNormalBand } from 'react-sparklines';

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Form,
  FormGroup,
  Label,
  Input,
  ListGroupItem,
  ListGroup,
  Media,
  NavItem,
  NavLink,
  Nav,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import ReportHeader from "components/Headers/ReportHeader.js";

// Auth actions
import {
  SET_FILTER_TIMEFRAME
} from 'store/actions';

// Import report blocks
import KPI from "./blocks/kpi.js";
import Chart from "./blocks/chart.js";
import ReportTable from "./blocks/table.js";

import {
  chartOptions,
  parseOptions,
  chartExample1
} from "variables/charts.domains.js";

// Cube.js
import cubejs from '@cubejs-client/core';
const cubejsApi = cubejs(
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NzI3ODEwMDF9.zsDKbAU_u8oH5aqAu6kJPrYPxmkQKSeWUJynh1vwrFQ',
  { apiUrl: 'https://striking-fittstown.gcp-us-central1.cubecloudapp.dev/cubejs-api/v1' }
);

function boxMullerRandom () {
    let phase = false,
        x1, x2, w, z;

    return (function() {

        if (phase = !phase) {
            do {
                x1 = 2.0 * Math.random() - 1.0;
                x2 = 2.0 * Math.random() - 1.0;
                w = x1 * x1 + x2 * x2;
            } while (w >= 1.0);

            w = Math.sqrt((-2.0 * Math.log(w)) / w);
            return x1 * w;
        } else {
            return x2 * w;
        }
    })();
}

// Random data generator
function randomData(n = 30) {
  return Array.apply(0, Array(n)).map(boxMullerRandom);
}

// Report block extractor
function generateBlock(report, index) {
  return (
    {
      'kpi': <KPI report={report} index={index} cubejsApi={cubejsApi} />,
      'chart': <Chart report={report} index={index} cubejsApi={cubejsApi} />,
      'table': <ReportTable report={report} index={index} cubejsApi={cubejsApi} />
    }[report.type]
  )
}

function ViewReport({ profile, knowledge, model, report, dispatch }) {
  // Get context
  const { query } = useParams();
  console.log("View Report: ", query);
  console.log("Knowledge:", knowledge);
  console.log("Report:", report);

  // States
  const [startDate, setStartDate] = React.useState(report.filters.timeframe.startDate);
  const [endDate, setEndDate] = React.useState(report.filters.timeframe.endDate);
  const [activeNav, setActiveNav] = React.useState(1);
  const listRef = React.useRef(null);

  const handleReactDatetimeChange = (who, date) => {
    // Format date
    const formattedDate = date.format('YYYY-MM-DD');
    console.log("Updating date: ", who, formattedDate);

    // Set startDate
    if(who == "startDate") {
      if(startDate > endDate) {
        // Create new end date
        let newEndDate = startDate.add('days', 7).format('YYYY-MM-DD');
        setEndDate(formattedDate);
      }

      // Set start date
      setStartDate(formattedDate);
    }

    // Set startDate
    if(who == "endDate") {
      setEndDate(formattedDate);
    }

    // Update timeframe
    dispatch({
      type: SET_FILTER_TIMEFRAME,
      payload: {
        startDate: startDate,
        endDate: endDate
      }
    });
  };

  const getClassNameReactDatetimeDays = (date) => {
    if (startDate && endDate) {
    }
    if (startDate && endDate && startDate._d + "" !== endDate._d + "") {
      if (
        new Date(endDate._d + "") > new Date(date._d + "") &&
        new Date(startDate._d + "") < new Date(date._d + "")
      ) {
        return " middle-date";
      }
      if (endDate._d + "" === date._d + "") {
        return " end-date";
      }
      if (startDate._d + "" === date._d + "") {
        return " start-date";
      }
    }
    return "";
  };

  return (
    <>
      <ReportHeader
        headline="Client Report"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col xl="3">
            <ListGroup style={{
              marginBottom: 30
            }}>
              <ListGroupItem
                className={`list-group-item-action flex-column align-items-start py-4 px-4`}
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  console.log("change report")
                }}
                active={true}
                style={{
                  height: 96
                }}
              >
                <Row className="align-items-center" style={{ paddingTop: 10 }}>
                  <div className="col ml--2">
                    <h4 className="mb-0">
                      <a href="" onClick={(e) => e.preventDefault()} className={`text-white`}>
                        Marketing Performance
                      </a>
                    </h4>
                  </div>
                </Row>
              </ListGroupItem>
            </ListGroup>
            <Card>
              <CardHeader>
                <h3 className="mb-0">Timeframe</h3>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row className="input-daterange datepicker align-items-center">
                    <Col xs={12} sm={6}>
                      <label className=" form-control-label">
                        Start Date
                      </label>
                      <FormGroup>
                        <ReactDatetime
                          inputProps={{
                            placeholder: "Date Picker Here",
                          }}
                          value={startDate}
                          timeFormat={false}
                          onChange={(e) =>
                            handleReactDatetimeChange("startDate", e)
                          }
                          renderDay={(props, currentDate, selectedDate) => {
                            let classes = props.className;
                            classes +=
                              getClassNameReactDatetimeDays(currentDate);
                            return (
                              <td {...props} className={classes}>
                                {currentDate.date()}
                              </td>
                            );
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={6}>
                      <FormGroup>
                        <label className=" form-control-label">
                          End Date
                        </label>
                        <ReactDatetime
                          inputProps={{
                            placeholder: "Date Picker Here",
                          }}
                          value={endDate}
                          timeFormat={false}
                          onChange={(e) =>
                            handleReactDatetimeChange("endDate", e)
                          }
                          renderDay={(props, currentDate, selectedDate) => {
                            let classes = props.className;
                            classes +=
                              getClassNameReactDatetimeDays(currentDate);
                            return (
                              <td {...props} className={classes}>
                                {currentDate.date()}
                              </td>
                            );
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
          <Col xl="9">
            <Row>
              <Col xl="12">
                <Card className="bg-gradient-primary border-0">
                  <CardBody>
                    <Row>
                      <Col xl="9">
                        <Button onClick={() => { console.log("selected customer"); }} color="secondary" size="md" type="button">
                          Facebook
                        </Button>
                      </Col>
                      <Col xl="3">
                        <Input
                          className="form-control-sm"
                          type="text"
                          style={{
                            position: 'absolute',
                            width: '80%',
                            top: 5
                          }}
                        />
                        <div
                          className="icon icon-shape bg-white text-dark rounded-circle shadow"
                          style={{
                            position: 'absolute',
                            right: '10%',
                            top: -2
                          }}
                        >
                          <i className="ni ni-bulb-61" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Card>
              <CardBody>
                { report.readouts.map((report, index) => (
                  generateBlock(report, index)
                ))}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

// Connect to store
const ConnectedViewReport = connect(state => ({
  profile: state.profile,
  knowledge: state.knowledge,
  model: state.model,
  report: state.report
}))(ViewReport);

export default ConnectedViewReport;
