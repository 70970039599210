/*
 *  Agent - Chat Component
 *  ---------
 *  Checkout module
 */

// React imports
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

// node.js library that concatenates classes (strings)
import classnames from "classnames";

// API (call for results)
import axios from 'axios';

// Message types
import WelcomeMessage from './types/Welcome';
import QueryMessage from './types/Query';
import ResponseMesage from './types/Response';
import ThreadMessage from './types/Thread';

// Message template
function generate(message) {
  // Return template
  return (
    <>
      {
        {
          'welcome': <WelcomeMessage message={message} />,
          'query': <QueryMessage message={message} />,
          'response': <ResponseMesage message={message} />,
          'thread': <ThreadMessage message={message} />
        }[message.type]
      }
    </>
  )
}

class ChatMessage extends React.Component {
  // UI state
  state = {
    query: ""
  }

  // Constructor
  constructor(props) {
    super(props);
  }


  render() {
    // Passed in props
    const {
      message
    } = this.props;

    return (
      <>
        { generate(message) }
      </>
    )
  }
}

export default ChatMessage
