/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

// node.js library that concatenates classes (strings)
import classnames from "classnames";

// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  CardImg,
  Button,
  Label,
  Input,
  Table,
  Container,
  Row,
  Col
} from "reactstrap";

// Formatting
import accounting from 'accounting-js';

// core components
import KnowledgeHeader from "components/Headers/KnowledgeHeader.js";

// Auth actions
import {
  INIITIALIZE_INSIGHTS,
  SET_ACTIVE_REPORT,
  SET_PERSONA,
  SET_QUERY
} from 'store/actions';

function ViewCustomer({ profile, account, knowledge, insights, report, model, dispatch }) {
  console.log("Account:", account);
  console.log("Knowledge:", knowledge);
  console.log("Insights: ", insights);
  console.log("Report:", report);

  // Get history
  const history = useHistory();

  // Query iunput
  const [query, setQuery] = React.useState("");

  // Handle text query change
  const handleChange = (e) => {
    // Set query
    setQuery(e.target.value);
  }

  // States (Report)
  const [startDate, setStartDate] = React.useState(report.filters.timeframe.startDate);
  const [endDate, setEndDate] = React.useState(report.filters.timeframe.endDate);
  const [activeNav, setActiveNav] = React.useState(1);
  const listRef = React.useRef(null);

  // Sync insights
  useEffect(() => {

  }, []);


  return (
    <>
      <KnowledgeHeader
        headline="Customer Profile"
        description=""
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col md="12">
            <Card className="bg-primary">
              <CardBody>
                <Row>
                  <Col md="3">
                    <Card className="card-profile">
                      <CardImg
                        alt="..."
                        src="https://media.istockphoto.com/id/1130025929/photo/santa-ana.jpg?s=612x612&w=0&k=20&c=JuBIl5b_wsggt06OBkFAuk3Fq25WYlnvjGNZ_xChBNg="
                        top
                      />
                      <Row className="justify-content-center">
                        <Col className="order-lg-2" lg="3">
                          <div className="card-profile-image">
                            <a href="#pablo" onClick={(e) => e.preventDefault()}>
                              <img
                                alt="..."
                                className="rounded-circle bg-gradient-secondary"
                                src="https://static.vecteezy.com/system/resources/previews/001/206/101/non_2x/man-png.png"
                              />
                            </a>
                          </div>
                        </Col>
                      </Row>
                      <CardBody className="pt-0">
                        <div className="text-center mt-7">
                          <h5 className="h3">
                            { model.items[0].name }
                            <span className="font-weight-light">{ model.items[0].age != "" ? `, ${model.items[0].age}` : ''}</span>
                          </h5>
                          <div className="h5 font-weight-300">
                            <i className="ni location_pin mr-2" />
                            Santa Ana, CA
                          </div>
                          <div className="h5 mt-4">
                            <i className="ni business_briefcase-24 mr-2" />
                            { model.items[0].jobTitle } - { model.items[0].industry }
                          </div>
                          <div>
                            <i className="ni education_hat mr-2" />
                            { model.items[0].education }
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="9">
                    <Row>
                      <Col xl="12">
                        <div className="customer-profile" style={{
                          padding: '10px 10px 0px 10px'
                        }}>
                          <h1 className="text-white mb-0" style={{ fontSize: 20}}>Business Casual ∙ Trendsetters</h1>

                          <h2 className="text-secondary display-3" style={{ fontSize: 26}}>Legal Professional Seeking Versatile, Trendy Wardrobe Pieces</h2>
                          <p className="lead text-white" style={{ fontSize: 18}}>
                            Rami Nabi is a 42 year old male with a background in American History from UC Santa Barbara and Real Estate from Chapman University. Currently, Rami works as an attorney in a law practice, focusing on legal research and litigation.
                          </p>
                          <p className="lead text-white" style={{ fontSize: 18}}>
                            As a business casual dresser, Rami is looking for a stylish and versatile shirt that can be worn to the office or on a night out. He likes to stay on top of the latest trends and is always looking for new ways to express himself in his wardrobe. He loves quality materials and is willing to invest in clothing that will last for years.
                          </p>
                          <hr/>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      { report.kpis[1].map((kpi, idx) => (
                        <Col xl="4">
                          <Card className={`card-stats text-white bg-white`}>
                            <CardBody>
                              <Row>
                                <div className="col">
                                  <CardTitle
                                    tag="h5"
                                    className={`text-uppercase text-muted mb-0 text-primary`}
                                  >
                                    { kpi.name }
                                  </CardTitle>
                                  <span className={`h2 font-weight-bold mb-0 ${(kpi.highlight) ? 'text-white' : ''}`}>{ kpi.value }</span>
                                </div>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xl="4">
            <Card>
              <CardBody>
                <CardTitle className="pb-2 mb-3 border-bottom" tag="h3">
                  <span>Next Predicted Action</span>
                </CardTitle>
                <Row className="mb-3">
                  <Col md="1"></Col>
                  <Label
                    className="form-control-label"
                    htmlFor="example-text-input"
                    md="4"
                  >
                    Purchase
                  </Label>
                  <Col md="6">
                    <Row className="col-form-label">
                      <div>7 - 14 Days (80%)</div>
                    </Row>
                  </Col>
                  <Col md="1"></Col>
                </Row>
                <Row className="mb-3">
                  <Col md="1"></Col>
                  <Label
                    className="form-control-label"
                    htmlFor="example-text-input"
                    md="4"
                  >
                    Site Visit
                  </Label>
                  <Col md="6">
                    <Row className="col-form-label">
                      <div>3 - 5 Days (90%)</div>
                    </Row>
                  </Col>
                  <Col md="1"></Col>
                </Row>
                <Row>
                  <Col md="1"></Col>
                  <Label
                    className="form-control-label"
                    htmlFor="example-text-input"
                    md="4"
                  >
                    Email Engagement
                  </Label>
                  <Col md="6">
                    <Row className="col-form-label">
                      <div>3 - 5 Days (90%)</div>
                    </Row>
                  </Col>
                  <Col md="1"></Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle className="pb-2 mb-3 border-bottom" tag="h3">
                  <span>Key Demographics</span>
                </CardTitle>
                <Row>
                  <Col md="1"></Col>
                  <Label
                    className="form-control-label"
                    htmlFor="example-text-input"
                    md="4"
                  >
                    Age
                  </Label>
                  <Col md="6">
                    <Row className="col-form-label">
                      <div>42</div>
                    </Row>
                  </Col>
                  <Col md="1"></Col>
                </Row>
                <Row>
                  <Col md="1"></Col>
                  <Label
                    className="form-control-label"
                    htmlFor="example-text-input"
                    md="4"
                  >
                    Gender
                  </Label>
                  <Col md="6">
                    <Row className="col-form-label">
                      <div>Male</div>
                    </Row>
                  </Col>
                  <Col md="1"></Col>
                </Row>
                <Row>
                  <Col md="1"></Col>
                  <Label
                    className="form-control-label"
                    htmlFor="example-text-input"
                    md="4"
                  >
                    Household Income
                  </Label>
                  <Col md="6">
                    <Row className="col-form-label">
                      <div>$450k - $550k</div>
                    </Row>
                  </Col>
                  <Col md="1"></Col>
                </Row>
                <Row>
                  <Col md="1"></Col>
                  <Label
                    className="form-control-label"
                    htmlFor="example-text-input"
                    md="4"
                  >
                    Current Job Tenure
                  </Label>
                  <Col md="6">
                    <Row className="col-form-label">
                      <div>9 Years</div>
                    </Row>
                  </Col>
                  <Col md="1"></Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg="8">
            <Card
              className="bg-gradient-primary border-0"
              style={{
                height: 140
              }}
            >
              <CardBody>
                <Row>
                  <Col md="12">
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Ask this customer a question..."
                      style={{
                        zIndex: 100000000,
                        position: 'absolute',
                        width: '90%',
                        top: 5
                      }}
                      onChange={handleChange}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          // Set persona
                          dispatch({
                            type: SET_PERSONA,
                            payload: {
                              // persona: (model.entity && model.entity.persona) ? model.entity.persona : ""
                              persona: `You are Rami Nabi, a 42 year old male with a background in American History from UC Santa Barbara and Real Estate from Chapman University. Currently, Rami works as an attorney in a law practice, focusing on legal research and litigation. \nAs a business casual dresser, you are looking for a stylish and versatile shirt that can be worn to the office or on a night out. You like to stay on top of the latest trends and is always looking for new ways to express himself in his wardrobe. You loves quality materials and is willing to invest in clothing that will last for years. \nYou became a customer of Teddy Stratford on February 29th, 2023 when you purchased a Large Orange Pinstripe Oxford.\nYou then returned the item on March 6th, 2023 and mentioned product fit as a primary issue.`
                            }
                          });

                          // Set initial query
                          if(query.length > 0) {
                            dispatch({
                              type: SET_QUERY,
                              payload: {
                                query: query
                              }
                            });
                          }

                          // Go to agent
                          history.push(`/admin/knowledge/agent`);
                        }
                      }}
                    />
                    <div
                      className="icon icon-shape bg-white text-dark rounded-circle shadow"
                      style={{
                        zIndex: 200000000,
                        position: 'absolute',
                        right: '2%',
                        top: -2,
                        height: 80,
                        width: 80,
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        // Set persona
                        dispatch({
                          type: SET_PERSONA,
                          payload: {
                            // persona: (model.entity && model.entity.persona) ? model.entity.persona : ""
                            persona: `You are Rami Nabi, a 42 year old male with a background in American History from UC Santa Barbara and Real Estate from Chapman University. Currently, Rami works as an attorney in a law practice, focusing on legal research and litigation. \nAs a business casual dresser, you are looking for a stylish and versatile shirt that can be worn to the office or on a night out. You like to stay on top of the latest trends and is always looking for new ways to express himself in his wardrobe. You loves quality materials and is willing to invest in clothing that will last for years. \nYou became a customer of Teddy Stratford on February 29th, 2023 when you purchased a Large Orange Pinstripe Oxford.\nYou then returned the item on March 6th, 2023 and mentioned product fit as a primary issue.`
                          }
                        });

                        // Navigate to agent
                        history.push(`/admin/knowledge/agent`);
                      }}
                    >
                      <i className="ni ni-books" />
                    </div>
                  </Col>
                  <Col
                    md="12"
                    style={{
                      paddingTop: 65
                    }}
                  >
                    <Button onClick={() => { history.push(`/admin/knowledge/agent`); }} color="default" size="sm" type="button">
                      purchase motivations
                    </Button>
                    <Button onClick={() => { history.push(`/admin/knowledge/agent`); }} color="default" size="sm" type="button">
                      competing priorties
                    </Button>
                    <Button onClick={() => { history.push(`/admin/knowledge/agent`); }} color="default" size="sm" type="button">
                      personal motivations
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardHeader className="bg-transparent">
                <h3 className="mb-0">Recent Customer Activity</h3>
              </CardHeader>
              <CardBody>
                <div
                  className="timeline timeline-one-side"
                  data-timeline-axis-style="dashed"
                  data-timeline-content="axis"
                >
                  <div className="timeline-block">
                    <Row>
                      <span className="timeline-step badge-danger">
                        <i className="ni ni-bell-55" />
                      </span>
                      <Col xl="8">
                        <div className="timeline-content">
                          <small className="text-muted font-weight-bold">
                            March 6th, 2023 | 4:00 PM
                          </small>
                          <h5 className="mt-3 mb-0">Product Issue</h5>
                          <p className="text-sm mt-1 mb-0">
                            Rami had an issue with the product because it lacked the quality materials and fashionable style he was looking for.
                          </p>
                          <div className="mt-3">
                            <Badge color="danger" pill>
                              return
                            </Badge>
                            <Badge color="danger" pill>
                              product fit
                            </Badge>
                          </div>
                        </div>
                      </Col>
                      <Col xl="4">
                        <div>
                          <Button
                            className="bg-secondary"
                            onClick={(e) => {
                              history.push(`/admin/knowledge/agent`);
                            }}
                          >
                            Learn More
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="timeline-block">
                    <Row>
                      <span className="timeline-step badge-success">
                        <i className="ni ni-money-coins" />
                      </span>
                      <Col xl="8">
                        <div className="timeline-content">
                          <small className="text-muted font-weight-bold">
                            February 29th, 2023 | 10:30 AM
                          </small>
                          <h5 className="mt-3 mb-0">Placed Order</h5>
                          <p className="text-sm mt-1 mb-0">
                            Rami was looking for a high-quality and stylish shirt that could transition from the office to a night out, so he invested in a versatile piece that would last.
                          </p>
                          <div className="mt-3">
                            <Badge color="success" pill>
                              the oxford
                            </Badge>
                            <Badge color="success" pill>
                              orange pinstripe
                            </Badge>
                            <Badge color="success" pill>
                              large
                            </Badge>
                          </div>
                        </div>
                      </Col>
                      <Col xl="4">
                        <div>
                          <Button
                            className="bg-secondary"
                            onClick={(e) => {
                              history.push(`/admin/knowledge/agent`);
                            }}
                          >
                            Learn More
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="timeline-block">
                    <Row>
                      <span className="timeline-step badge-secondary">
                        <i className="ni ni-tag" />
                      </span>
                      <Col xl="8">
                        <div className="timeline-content">
                          <small className="text-muted font-weight-bold">
                            February 26th, 2023 | 3:00 PM
                          </small>
                          <h5 className="mt-3 mb-0">Browsed Site Catalog</h5>
                          <p className="text-sm mt-1 mb-0">
                            Rami is browsing the product catalog to find a stylish and versatile shirt that can be worn to the office or on a night out, made from quality materials that will last for years, to stay on top of the latest trends and express himself in his wardrobe.
                          </p>
                          <div className="mt-3">
                            <Badge color="secondary" pill>
                              dress shirts
                            </Badge>
                            <Badge color="secondary" pill>
                              read reviews
                            </Badge>
                            <Badge color="secondary" pill>
                              casual shirts
                            </Badge>
                          </div>
                        </div>
                      </Col>
                      <Col xl="4">
                        <div>
                          <Button
                            className="bg-secondary"
                            onClick={(e) => {
                              history.push(`/admin/knowledge/agent`);
                            }}
                          >
                            Learn More
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="timeline-block">
                    <Row>
                      <span className="timeline-step badge-secondary">
                        <i className="ni ni-tag" />
                      </span>
                      <Col xl="8">
                        <div className="timeline-content">
                          <small className="text-muted font-weight-bold">
                            February 21st, 2023 | 9:20 AM
                          </small>
                          <h5 className="mt-3 mb-0">Initial Visit</h5>
                          <p className="text-sm mt-1 mb-0">
                            Rami clicked on the Facebook ad to the site because he was interested in finding a stylish, versatile shirt that he could wear to the office or on a night out with quality materials that will last for years.
                          </p>
                          <div className="mt-3">
                            <Badge color="secondary" pill>
                              facebook ad
                            </Badge>
                            <Badge color="secondary" pill>
                              mobile device
                            </Badge>
                            <Badge color="secondary" pill>
                              at work
                            </Badge>
                          </div>
                        </div>
                      </Col>
                      <Col xl="4">
                        <div>
                          <Button
                            className="bg-secondary"
                            onClick={(e) => {
                              history.push(`/admin/knowledge/agent`);
                            }}
                          >
                            Learn More
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

// Connect to store
const ConnectedViewCustomer = connect(state => ({
  profile: state.profile,
  account: state.account,
  knowledge: state.knowledge,
  insights: state.insights,
  report: state.report,
  model: state.model
}))(ViewCustomer);

export default ConnectedViewCustomer;
