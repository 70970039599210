/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

// node.js library that concatenates classes (strings)
import classnames from "classnames";

// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";

// react plugin used to create DropdownMenu for selecting items
import Select2 from "react-select2-wrapper";

// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";

// Sparklines
import { Sparklines, SparklinesLine, SparklinesBars, SparklinesSpots, SparklinesNormalBand } from 'react-sparklines';

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Form,
  FormGroup,
  Label,
  Input,
  ListGroupItem,
  ListGroup,
  Media,
  NavItem,
  NavLink,
  Nav,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Spinner
} from "reactstrap";

// Formatting
import accounting from 'accounting-js';

// core components
import KnowledgeHeader from "components/Headers/KnowledgeHeader.js";

// Auth actions
import {
  INIITIALIZE_INSIGHTS,
  SET_ACTIVE_REPORT
} from 'store/actions';

// Import report blocks
import CustomerChart from "./blocks/chart.js";

function ViewCampaign({ profile, account, knowledge, insights, report, model, dispatch }) {
  console.log("Account:", account);
  console.log("Knowledge:", knowledge);
  console.log("Insights: ", insights);
  console.log("Report:", report);

  // Get history
  const history = useHistory();

  // States (Report)
  const [startDate, setStartDate] = React.useState(report.filters.timeframe.startDate);
  const [endDate, setEndDate] = React.useState(report.filters.timeframe.endDate);
  const [activeNav, setActiveNav] = React.useState(1);
  const listRef = React.useRef(null);

  // Sync insights
  useEffect(() => {

  }, []);

  return (
    <>
      <KnowledgeHeader
        headline="[CF] Prospecting - Conversion"
        description=""
      />
      <Container className="mt--6" fluid>
        <CustomerChart />
        <Row>
          <Col xl="4">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0"
                    >
                      Expected Lifetime Value per Customer
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0">$345.23</span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                      <i className="ni ni-bag-17" />
                    </div>
                  </Col>
                </Row>
                <p className="mt-3 mb-0 text-sm">
                  <span className="text-success mr-2">
                    <i className="fa fa-arrow-up" /> 3.48%
                  </span>{" "}
                  <span className="text-nowrap">Since last month</span>
                </p>
              </CardBody>
            </Card>
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0"
                    >
                      Average Cost to Acquire per Customer
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0">$45.23</span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                      <i className="ni ni-money-coins" />
                    </div>
                  </Col>
                </Row>
                <p className="mt-3 mb-0 text-sm">
                  <span className="text-danger mr-2">
                    <i className="fa fa-arrow-down" /> 3.48%
                  </span>{" "}
                  <span className="text-nowrap">Since last month</span>
                </p>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle className="pb-2 mb-3 border-bottom" tag="h3">
                  <span>Next Predicted Action</span>
                </CardTitle>
                <Row className="mb-3">
                  <Col md="1"></Col>
                  <Label
                    className="form-control-label"
                    htmlFor="example-text-input"
                    md="4"
                  >
                    Purchase
                  </Label>
                  <Col md="6">
                    <Row className="col-form-label">
                      <div>7 - 14 Days (80%)</div>
                    </Row>
                  </Col>
                  <Col md="1"></Col>
                </Row>
                <Row className="mb-3">
                  <Col md="1"></Col>
                  <Label
                    className="form-control-label"
                    htmlFor="example-text-input"
                    md="4"
                  >
                    Site Visit
                  </Label>
                  <Col md="6">
                    <Row className="col-form-label">
                      <div>3 - 5 Days (90%)</div>
                    </Row>
                  </Col>
                  <Col md="1"></Col>
                </Row>
                <Row>
                  <Col md="1"></Col>
                  <Label
                    className="form-control-label"
                    htmlFor="example-text-input"
                    md="4"
                  >
                    Email Engagement
                  </Label>
                  <Col md="6">
                    <Row className="col-form-label">
                      <div>3 - 5 Days (90%)</div>
                    </Row>
                  </Col>
                  <Col md="1"></Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle className="pb-2 mb-3 border-bottom" tag="h3">
                  <span>Customer Summary</span>
                </CardTitle>
                <Row className="mb-3">
                  <Col md="1"></Col>
                  <Label
                    className="form-control-label"
                    htmlFor="example-text-input"
                    md="4"
                  >
                    Age
                  </Label>
                  <Col md="6">
                    <Row className="col-form-label">
                      <div>30 - 45 (65%)</div>
                    </Row>
                    <Row className="col-form-label">
                      <div>45 - 55 (32%)</div>
                    </Row>
                  </Col>
                  <Col md="1"></Col>
                </Row>
                <Row>
                  <Col md="1"></Col>
                  <Label
                    className="form-control-label"
                    htmlFor="example-text-input"
                    md="4"
                  >
                    Gender
                  </Label>
                  <Col md="6">
                    <Row className="col-form-label">
                      <div>Male (95%)</div>
                    </Row>
                    <Row className="col-form-label">
                      <div>Female (5%)</div>
                    </Row>
                  </Col>
                  <Col md="1"></Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xl="8">
            <Row>
              <Col xl="12">
                <Card
                  className="bg-gradient-primary border-0"
                  style={{
                    height: 140
                  }}
                >
                  <CardBody>
                    <Row>
                      <Col md="12">
                        <Input
                          className="form-control"
                          type="text"
                          placeholder="Search customers activity..."
                          style={{
                            zIndex: 100000000,
                            position: 'absolute',
                            width: '90%',
                            top: 5
                          }}
                          onChange={() => { console.log("changing...")}}
                        />
                        <div
                          className="icon icon-shape bg-white text-dark rounded-circle shadow"
                          style={{
                            zIndex: 200000000,
                            position: 'absolute',
                            right: '2%',
                            top: -2,
                            height: 80,
                            width: 80
                          }}
                        >
                          <i className="ni ni-books" />
                        </div>
                      </Col>
                      <Col
                        md="12"
                        style={{
                          paddingTop: 65
                        }}
                      >
                        <Button onClick={() => { console.log("selected customer"); }} color="default" size="sm" type="button">
                          first time purchasers
                        </Button>
                        <Button onClick={() => { console.log("selected customer"); }} color="default" size="sm" type="button">
                          purchased on visit
                        </Button>
                        <Button onClick={() => { console.log("selected customer"); }} color="default" size="sm" type="button">
                          multiple visits
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <div className="col">
                <Card>
                  <CardHeader className="border-0">
                    <h3 className="mb-0">{`${model.domain.name}`}</h3>
                  </CardHeader>

                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th className="sort" data-sort="name" scope="col">
                          Name
                        </th>
                        <th className="sort" data-sort="value" scope="col">
                          Lifetime Value
                        </th>
                        <th className="sort" data-sort="days_to_purchase" scope="col">
                          Time to First Purchase
                        </th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody className="list">
                      { model.items.map((item, index) => (
                        <tr key={index}>
                          <th scope="row">
                            <div className="col ml--2">
                              <h4 className="mb-0">
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                  { item.name }
                                </a>
                              </h4>
                              <small>{ item.age } | { item.industry } | { item.jobTitle }</small>
                            </div>
                          </th>
                          <td className="text-center">{ item.value }</td>
                          <td className="text-center">{ item.days_to_purchase }</td>
                          <td className="text-right">
                            <Button onClick={() => { history.push("/admin/insights/customers/view/1") }} color="primary" size="md" type="button" style={{
                              width: 120
                            }}>
                              View
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  <CardFooter className="py-4">
                    <nav aria-label="...">
                      <Pagination
                        className="pagination justify-content-end mb-0"
                        listClassName="justify-content-end mb-0"
                      >
                        <PaginationItem className="disabled">
                          <PaginationLink
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            tabIndex="-1"
                          >
                            <i className="fas fa-angle-left" />
                            <span className="sr-only">Previous</span>
                          </PaginationLink>
                        </PaginationItem>
                        <PaginationItem className="active">
                          <PaginationLink
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            1
                          </PaginationLink>
                        </PaginationItem>
                        <PaginationItem>
                          <PaginationLink
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            2 <span className="sr-only">(current)</span>
                          </PaginationLink>
                        </PaginationItem>
                        <PaginationItem>
                          <PaginationLink
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            3
                          </PaginationLink>
                        </PaginationItem>
                        <PaginationItem>
                          <PaginationLink
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-angle-right" />
                            <span className="sr-only">Next</span>
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination>
                    </nav>
                  </CardFooter>
                </Card>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

// Connect to store
const ConnectedViewCampaign = connect(state => ({
  profile: state.profile,
  account: state.account,
  knowledge: state.knowledge,
  insights: state.insights,
  report: state.report,
  model: state.model
}))(ViewCampaign);

export default ConnectedViewCampaign;
