// React imports
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

// node.js library that concatenates classes (strings)
import classnames from "classnames";

// API (call for results)
import axios from 'axios';

// UI Imports
import Cursor from './../ui/Cursor';

class ResponseMessage extends React.Component {
  // UI state
  state = {
    typing: true,
    content: ""
  }

  // Constructor
  constructor(props) {
    super(props);
  }

  // On mount
  componentDidMount() {

  }

  render() {
    // Get props
    const {
      message
    } = this.props;

    // Passed in props
    const {
      typing,
      content
    } = this.state;

    // Trigger effect on agent change
    // const _this = this;
    // useEffect(() => {
    //   // Trigger type-writer effect
    //   console.log("Response - Agent Change: ", _this.props.message.id, _this.props.agent);
    //   let i = 0;
    //   const intervalId = setInterval(() => {
    //     // Udpate content
    //     _this.setState({
    //       content: _this.props.message.content.slice(0, i)
    //     });
    //
    //     // Increment
    //     i++;
    //
    //     // Stop at end
    //     if (i > _this.props.message.content.length) {
    //       // Clear interval
    //       clearInterval(intervalId);
    //
    //       // Set end of typing
    //       _this.setState({
    //         typing: false
    //       });
    //
    //       // Display next message
    //       // _this.props.dispatch('chat/messages/next', {
    //       //   id: _this.props.message.id
    //       // });
    //     }
    //   }, 20);
    // }, [message.content]);

    // useEffect(() => {
    //   // Trigger type-writer effect
    //   if(_this.props.message.id == _this.props.agent.typing) {
    //     console.log("Response - Agent Change: ", _this.props.message.id, _this.props.agent);
    //     let i = 0;
    //     const intervalId = setInterval(() => {
    //       // Udpate content
    //       _this.setState({
    //         content: _this.props.message.content.slice(0, i)
    //       });
    //
    //       // // Dispatch typing event
    //       // window.dispatchEvent(new CustomEvent('agent:typing', {
    //       //   message: _this.props.message
    //       // }));
    //
    //       // Increment
    //       i++;
    //
    //       // Stop at end
    //       if (i > _this.props.message.content.length) {
    //         // Clear interval
    //         clearInterval(intervalId);
    //
    //         // Set end of typing
    //         _this.setState({
    //           typing: false
    //         });
    //
    //         // Display next message
    //         _this.props.dispatch('chat/messages/next', {
    //           id: _this.props.message.id
    //         });
    //       }
    //     }, 20);
    //   }
    // }, [agent.typing]);

    return (
      <div className="row justify-content-start mb-2">
          <div className="col-auto">
              <div className="card bg-gradient-default text-black">
                  <div className="card-body p-2">
                      <p className="mb-1">
                          {message.content}
                      </p>
                  </div>
              </div>
          </div>
      </div>
    )
  }
}

export default ResponseMessage

/*

<>
  { message.data.display &&
    <div className="row justify-content-start mb-2">
        <div className="col-auto">
            <div className="card bg-gradient-default text-black">
                <div className="card-body p-2">
                    <p className="mb-1">
                        {content}{typing && <Cursor />}
                    </p>
                </div>
            </div>
        </div>
    </div>
  }
</>

*/
