// action - state management
import * as actionTypes from '../actions';

const initialState = {
    initialized: false,
    user: {
      uid: null,
      name: null
    },
    welcome: {
      complete: false,
      stage: 'sign-up'
    }
};

// ===========================|| PRODUCTS REDUCER ||=========================== //

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INITIALIZE_PROFILE: {
          console.log("Initializing profile...", action.payload);

          // Update state
          return {
              ...state,
              initialized: true,
              user: (action.payload) ? action.payload : state.user,
              welcome: {
                complete: (action.payload && action.payload.complete) ? action.payload.complete : false,
                stage: 'sign-up'
              }
          };
        }
        default:
            return state;
    }
};

export default profileReducer;
