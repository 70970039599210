/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// React imports
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// nodejs library to set properties for components
import PropTypes from "prop-types";

// node.js library that concatenates classes (strings)
import classnames from "classnames";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Row,
  Col,
  ListGroupItem,
  ListGroup,
  Progress,
  Button
} from "reactstrap";

// javascipt plugin for creating charts
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';

// Import Member Growth
import MemberGrowthChart from "./Growth/Chart";
import MemberGrowthTotalKPIs from "./Growth/KPI.Total";
import MemberGrowthNewKPIs from "./Growth/KPI.New";
import MemberGrowthLocations from "./Growth/Locations";

function MembersChart({ profile, account, knowledge, model, report, cubejsApi }) {

  return (
    <Row>
      <Col md="12">
        <Card className="bg-primary">
          <CardHeader className="bg-transparent">
            <Row className="align-items-center">
              <div className="col">
                <h6 className="text-light text-uppercase ls-1 mb-1">
                  YourHealth
                </h6>
                <h5 className="h3 text-white mb-0">Membership Growth</h5>
              </div>
              <div className="col">

              </div>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <MemberGrowthTotalKPIs cubejsApi={cubejsApi} />
              <MemberGrowthNewKPIs cubejsApi={cubejsApi} />
            </Row>
            <Row style={{
              height: 300
            }}>
              <Col xl="3">
                <MemberGrowthLocations cubejsApi={cubejsApi} />
              </Col>
              <Col xl="9">
                <div style={{
                  height: '100%'
                }}>
                  <MemberGrowthChart cubejsApi={cubejsApi} />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

// Connect to store
const ConnectedMembersChart = connect(state => ({
  profile: state.profile,
  account: state.account,
  knowledge: state.knowledge,
  model: state.model,
  report: state.report
}))(MembersChart);

export default ConnectedMembersChart;
