/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// React imports
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

// node.js library that concatenates classes (strings)
import classnames from "classnames";

// javascipt plugin for creating charts
import { Chart } from "chart.js";

// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  FormGroup,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Container,
  Row,
  Col,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

// core components
import ProfileHeader from "components/Headers/ProfileHeader.js";

import {
  SET_ACTIVE_ENTITY
} from 'store/actions';

import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";

function Home({ profile, account, knowledge, insights, report, model, dispatch }) {

  // Get history
  const history = useHistory();

  // Handle artwork selection
  const selectEntity = (entity) => {
    // Update local store
    dispatch({
      type: SET_ACTIVE_ENTITY,
      payload: entity
    });

    // Got to review page
    history.push(`/admin/knowledge/personas/view/${entity.id}`);
  }

  return (
    <>
      <ProfileHeader />
      <Container className="mt--6" fluid>
        <Row>
          <Col xl="4">
            <Link to="/admin/insights/reports">
              <Card
                className="bg-gradient-primary border-0"
                style={{ cursor: 'pointer', height: 140 }}
              >
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        className="text-uppercase text-muted mb-0 text-white"
                        tag="h5"
                      >
                        Customer
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0 text-white">
                        Personas
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                        <i className="ni ni-single-02" />
                      </div>
                    </Col>
                  </Row>
                  <p className="mt-3 mb-0 text-sm">
                    <span className="text-success mr-2">
                      <i className="fa fa-arrow-up" /> 3.48%
                    </span>{" "}
                    <span className="text-white text-nowrap">Model Accuracy</span>
                  </p>
                </CardBody>
              </Card>
            </Link>
          </Col>

          <Col xl="8">
            <Card
              className="bg-gradient-primary border-0"
              style={{
                height: 140
              }}
            >
              <CardBody>
                <Row>
                  <Col md="12">
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Ask a question about your customers..."
                      style={{
                        zIndex: 100000000,
                        position: 'absolute',
                        width: '90%',
                        top: 5
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          // Ask question

                          // Go to agent
                          history.push(`/admin/knowledge/agent`);
                        }
                      }}
                    />
                    <div
                      className="icon icon-shape bg-white text-dark rounded-circle shadow"
                      style={{
                        zIndex: 200000000,
                        position: 'absolute',
                        right: '2%',
                        top: -2,
                        height: 80,
                        width: 80
                      }}
                    >
                      <i className="ni ni-books" />
                    </div>
                  </Col>
                  <Col
                    md="12"
                    style={{
                      paddingTop: 65
                    }}
                  >
                    <Button onClick={() => { console.log("selected customer"); }} color="default" size="sm" type="button">
                      highest lifetime value
                    </Button>
                    <Button onClick={() => { console.log("selected customer"); }} color="default" size="sm" type="button">
                      top referral sources
                    </Button>
                    <Button onClick={() => { console.log("selected customer"); }} color="default" size="sm" type="button">
                      top performing ads
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <Row>
                  <Col md="12">
                    <Card>
                      <CardHeader className="border-0">
                        <Row>
                          <Col xs="8">
                            <h3 className="mb-0">Persona Performance</h3>
                          </Col>
                          <Col className="text-right" xs="4">
                          </Col>
                        </Row>
                      </CardHeader>

                      <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                          <tr>
                            <th className="sort" data-sort="name" scope="col">
                              Name
                            </th>
                            <th className="sort" data-sort="matched" scope="col">
                              Matched
                            </th>
                            <th className="sort" data-sort="segments" scope="col">
                              Segments
                            </th>
                            <th className="sort" data-sort="value" scope="col">
                              Lifetime Value (Avg.)
                            </th>
                            <th className="sort" data-sort="model" scope="col">
                              Model Strength
                            </th>
                            <th scope="col" />
                          </tr>
                        </thead>
                        <tbody className="list">
                          { knowledge.entities.map((entity, index) => (
                            <tr key={index}>
                              <th scope="row">
                                {entity.name}
                              </th>
                              <td className="matched">{entity.total}</td>
                              <td className="segments">{entity.segments}</td>
                              <td className="value">{entity.value}</td>
                              <td className="model">
                                <div className="d-flex align-items-center">
                                  <span className="completion mr-2">{entity.strength}%</span>
                                  <div>
                                    <Progress max="100" value={entity.strength} color="primary" />
                                  </div>
                                </div>
                              </td>
                              <td className="text-right">
                                <UncontrolledDropdown group>
                                    <Button
                                      color="primary" size="sm" type="button"
                                      onClick={() => {
                                        // select entity
                                        selectEntity(entity);
                                      }}
                                      style={{
                                        width: 120
                                      }}
                                    >
                                      View
                                    </Button>
                                    <DropdownToggle
                                      caret
                                      color="primary"
                                    />
                                    <DropdownMenu>
                                      <DropdownItem disabled>
                                        Cancel
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Card>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

// Connect to store
const ConnectedHome = connect(state => ({
  profile: state.profile,
  account: state.account,
  knowledge: state.knowledge,
  insights: state.insights,
  report: state.report,
  model: state.model
}))(Home);

export default ConnectedHome;
