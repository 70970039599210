/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link, useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

// node.js library that concatenates classes (strings)
import classnames from "classnames";

// javascipt plugin for creating charts
import { Chart } from "chart.js";

// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";

// react plugin used to create DropdownMenu for selecting items
import Select2 from "react-select2-wrapper";

// Sparklines
import { Sparklines, SparklinesLine, SparklinesBars, SparklinesSpots, SparklinesNormalBand } from 'react-sparklines';

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  CardImg,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Form,
  FormGroup,
  Label,
  Input,
  ListGroupItem,
  ListGroup,
  Media,
  NavItem,
  NavLink,
  Nav,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import KnowledgeHeader from "components/Headers/KnowledgeHeader.js";

// Auth actions
import {
  CLEAR_MESSAGES,
  SET_QUERY,
  SET_PERSONA
} from 'store/actions';

function PersonaModel({ profile, knowledge, model, dispatch }) {
  // Get context
  const { id } = useParams();
  //console.log("View Model: ", id, model);

  // Get history
  const history = useHistory();

  // Query iunput
  const [query, setQuery] = React.useState("");

  // Handle text query change
  const handleChange = (e) => {
    // Set query
    setQuery(e.target.value);
  }

  return (
    <>
      <KnowledgeHeader
        headline={model.entity.name}
        description={model.entity.description}
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col xl="4">
            <Card className="card-profile">
              <CardImg
                alt="..."
                src="https://storage.googleapis.com/avant-garde-cdn/scratch/urban-coastal-city-skyline.png"
                style={{ height: 250 }}
                top
              />
              <Row className="justify-content-center">
                <Col className="order-lg-2" lg="3">
                  <div className="card-profile-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="rounded-circle bg-gradient-secondary"
                        src="https://static.vecteezy.com/system/resources/previews/001/206/101/non_2x/man-png.png"
                      />
                    </a>
                  </div>
                </Col>
              </Row>
              <CardBody className="pt-0">
                <div className="text-center mt-7">
                  <h5 className="h3">
                    Male
                    <span className="font-weight-light">, 30 - 55</span>
                  </h5>
                  <div className="h5 font-weight-300">
                    <i className="ni location_pin mr-2" />
                    Urban Coastal Cities
                  </div>
                  <div className="h4 mt-4">
                    Job Function
                  </div>
                  <div>
                    <i className="ni education_hat mr-2" />
                    Principal, Lawyer, Chief Executive Officer, Chief Technology Officer
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <Form>
                  <Select2
                    className="form-control"
                    defaultValue="1"
                    options={{
                      placeholder: "Select",
                    }}
                    data={[
                      { id: "1", text: "Purchase Criteria" },
                      { id: "2", text: "Favorite Clothing Brands" },
                      { id: "3", text: "Measures of Quality" }
                    ]}
                  />
                </Form>
              </CardHeader>
              <CardBody>
                <ListGroup className="list my--3" flush>
                  { model.factors.map((stat, index) => (
                    <ListGroupItem className="px-0">
                      <Row className="align-items-center">
                        <div className="col">
                          <h5>{stat.display}</h5>
                          <Progress
                            className="progress-xs mb-0"
                            color="primary"
                            max="100"
                            value={stat.value}
                          />
                        </div>
                        <Col className="col-auto">
                          <Button
                            color="primary" size="sm" type="button"
                            style={{
                              width: 100
                            }}
                            onClick={() => {
                              // Set persona
                              dispatch({
                                type: SET_PERSONA,
                                payload: {
                                  // persona: (model.entity && model.entity.persona) ? model.entity.persona : ""
                                  persona: `You are a business casual buying persona for a clothing retailer named Teddy Stratford that sells athletic fit button down shirts that zip.\nYou shop at this retailer looking for a stylish and versatile shirt that can be worn to the office or on a night out.\nYou are likely a male between the age of 30 and 55.\nYou likely live in an urban coastal city.\nYou likely have one of the following job functions:\n- Principal\n- Lawyer\n- Chief Executive Officer\n- Chief Technology Officer`
                                }
                              });

                              // Set initial message
                              dispatch({
                                type: CLEAR_MESSAGES,
                                payload: {
                                  content: stat.details
                                }
                              });

                              // Follow-up
                              dispatch({
                                type: SET_QUERY,
                                payload: {
                                  query: `Interesting. Can you elaborate on preferences around ${stat.summary.toLowerCase()}?`
                                }
                              });

                              // Navigate to agent
                              history.push(`/admin/knowledge/agent`);
                            }}
                          >
                            Ask
                          </Button>
                        </Col>
                      </Row>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
          <Col xl="8">
          <Card
            className="bg-gradient-primary border-0"
            style={{
              height: 140
            }}
          >
            <CardBody>
              <Row>
                <Col md="12">
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="Ask this persona a question..."
                    style={{
                      zIndex: 100000000,
                      position: 'absolute',
                      width: '90%',
                      top: 5
                    }}
                    onChange={handleChange}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        // Set persona
                        dispatch({
                          type: SET_PERSONA,
                          payload: {
                            // persona: (model.entity && model.entity.persona) ? model.entity.persona : ""
                            persona: `You are a business casual buying persona for a clothing retailer named Teddy Stratford that sells athletic fit button down shirts that zip.\nYou shop at this retailer looking for a stylish and versatile shirt that can be worn to the office or on a night out.\nYou are likely a male between the age of 30 and 55.\nYou likely live in an urban coastal city.\nYou likely have one of the following job functions:\n- Principal\n- Lawyer\n- Chief Executive Officer\n- Chief Technology Officer`
                          }
                        });

                        // Set initial query
                        if(query.length > 0) {
                          dispatch({
                            type: SET_QUERY,
                            payload: {
                              query: query
                            }
                          });
                        }

                        // Go to agent
                        history.push(`/admin/knowledge/agent`);
                      }
                    }}
                  />
                  <div
                    className="icon icon-shape bg-white text-dark rounded-circle shadow"
                    style={{
                      zIndex: 200000000,
                      position: 'absolute',
                      right: '2%',
                      top: -2,
                      height: 80,
                      width: 80,
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      // Set persona
                      dispatch({
                        type: SET_PERSONA,
                        payload: {
                          // persona: (model.entity && model.entity.persona) ? model.entity.persona : ""
                          persona: `You are a business casual buying persona for a clothing retailer named Teddy Stratford that sells athletic fit button down shirts that zip.\nYou shop at this retailer looking for a stylish and versatile shirt that can be worn to the office or on a night out.\nYou are likely a male between the age of 30 and 55.\nYou likely live in an urban coastal city.\nYou likely have one of the following job functions:\n- Principal\n- Lawyer\n- Chief Executive Officer\n- Chief Technology Officer`
                        }
                      });

                      // Navigate to agent
                      history.push(`/admin/knowledge/agent`);
                    }}
                  >
                    <i className="ni ni-books" />
                  </div>
                </Col>
                <Col
                  md="12"
                  style={{
                    paddingTop: 65
                  }}
                >
                  <Button onClick={() => { history.push(`/admin/knowledge/agent`); }} color="default" size="sm" type="button">
                    purchase criteria
                  </Button>
                  <Button onClick={() => { history.push(`/admin/knowledge/agent`); }} color="default" size="sm" type="button">
                    competing priorties
                  </Button>
                  <Button onClick={() => { history.push(`/admin/knowledge/agent`); }} color="default" size="sm" type="button">
                    personal motivations
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
            <Row>
              <div className="col">
                <Card>
                  <CardHeader className="border-0">
                    <h3 className="mb-0">{`${model.domain.name}`}</h3>
                  </CardHeader>

                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th className="sort" data-sort="name" scope="col">
                          Name
                        </th>
                        <th className="sort" data-sort="value" scope="col">
                          Last Visit
                        </th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody className="list">
                      { model.items.map((item, index) => (
                        <tr key={index}>
                          <th scope="row">
                            <div className="col ml--2">
                              <h4 className="mb-0">
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                  { item.name }
                                </a>
                              </h4>
                              <small>{ item.age } | { item.industry } | { item.jobTitle }</small>
                            </div>
                          </th>
                          <td></td>
                          <td className="text-right">
                            <Button onClick={() => { history.push("/admin/insights/customers/view/1") }} color="primary" size="md" type="button" style={{
                              width: 120
                            }}>
                              View
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  <CardFooter className="py-4">
                    <nav aria-label="...">
                      <Pagination
                        className="pagination justify-content-end mb-0"
                        listClassName="justify-content-end mb-0"
                      >
                        <PaginationItem className="disabled">
                          <PaginationLink
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            tabIndex="-1"
                          >
                            <i className="fas fa-angle-left" />
                            <span className="sr-only">Previous</span>
                          </PaginationLink>
                        </PaginationItem>
                        <PaginationItem className="active">
                          <PaginationLink
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            1
                          </PaginationLink>
                        </PaginationItem>
                        <PaginationItem>
                          <PaginationLink
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            2 <span className="sr-only">(current)</span>
                          </PaginationLink>
                        </PaginationItem>
                        <PaginationItem>
                          <PaginationLink
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            3
                          </PaginationLink>
                        </PaginationItem>
                        <PaginationItem>
                          <PaginationLink
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-angle-right" />
                            <span className="sr-only">Next</span>
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination>
                    </nav>
                  </CardFooter>
                </Card>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

// Connect to store
const ConnectedPersonaModel = connect(state => ({
  profile: state.profile,
  knowledge: state.knowledge,
  model: state.model
}))(PersonaModel);

export default ConnectedPersonaModel;
