/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// React
import React, { Component } from 'react';
import { connect } from 'react-redux';

// nodejs library to set properties for components
import PropTypes from "prop-types";

// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Spinner
} from "reactstrap";

// Formatting
import accounting from 'accounting-js';

// CubeJs
import cubejs from '@cubejs-client/core';
import { QueryRenderer } from '@cubejs-client/react';

// Pre-ready queries
function generateQuery(report, filters) {
  return {
    "measures": [
      "CampaignsPerformanceDaily.totalOrders",
      "CampaignsPerformanceDaily.totalSales",
      "CampaignsPerformanceDaily.totalSpend",
      "CampaignsPerformanceDaily.returnOnAdSpend",
      "CampaignsPerformanceDaily.daysToPurchase"
    ],
    "timeDimensions": [
      {
        "dimension": "CampaignsPerformanceDaily.orderDate",
        "dateRange": [
          filters.timeframe.startDate,
          filters.timeframe.endDate
        ]
      }
    ],
    "dimensions": [
      "CampaignsPerformanceDaily.campaignName"
    ],
    "order": {
      "CampaignsPerformanceDaily.totalOrders": "desc"
    },
    "filters": [
      {
        "member": "CampaignsPerformanceDaily.campaignName",
        "operator": "notContains",
        "values": [
          "Shopify"
        ]
      }
    ]
  };
}

// Closure for render
function render(name, index) {
  // Wrapper for query for all data
  function renderFromQuery({ resultSet, error }) {
    console.log("-> Results: ", resultSet);

    // Format data
    let data = {
      headers: [
        {
          display: "Campaign Name",
          name: "campaign_name"
        },
        {
          display: "Return on Ad Spend",
          name: "return_on_ad_spend"
        },
        {
          display: "Total Orders",
          name: "total_orders"
        },
        {
          display: "Total Sales",
          name: "total_sales"
        },
        {
          display: "Total Spend",
          name: "total_spend"
        },
        {
          display: "Days to Purchase",
          name: "days_to_purchase"
        }
      ],
      rows: []
    };

    // Parse result set
    if(resultSet && resultSet.loadResponse.results.length > 0) {
      for (var i = 0; i < resultSet.loadResponse.results[0].data.length; i++) {
        // Add to data
        data.rows.push({
          campaign_name: resultSet.loadResponse.results[0].data[i]['CampaignsPerformanceDaily.campaignName'],
          return_on_ad_spend: resultSet.loadResponse.results[0].data[i]['CampaignsPerformanceDaily.returnOnAdSpend'] ? resultSet.loadResponse.results[0].data[i]['CampaignsPerformanceDaily.returnOnAdSpend'].toFixed(2) : '-',
          total_orders: resultSet.loadResponse.results[0].data[i]['CampaignsPerformanceDaily.totalOrders'] ? resultSet.loadResponse.results[0].data[i]['CampaignsPerformanceDaily.totalOrders'] : '-',
          total_sales: resultSet.loadResponse.results[0].data[i]['CampaignsPerformanceDaily.totalSales'] ? accounting.formatMoney(resultSet.loadResponse.results[0].data[i]['CampaignsPerformanceDaily.totalSales']) : '-',
          total_spend: resultSet.loadResponse.results[0].data[i]['CampaignsPerformanceDaily.totalSpend'] ? accounting.formatMoney(resultSet.loadResponse.results[0].data[i]['CampaignsPerformanceDaily.totalSpend']) : '-',
          days_to_purchase: resultSet.loadResponse.results[0].data[i]['CampaignsPerformanceDaily.daysToPurchase'] ? resultSet.loadResponse.results[0].data[i]['CampaignsPerformanceDaily.daysToPurchase'].toFixed(2) : '-'
        });
      }
    }
    console.log("Data: ", data);

    // Return formatted table
    return (
      <>
        { data.rows.length == 0 ?
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          :
          <Row key={index}>
            <Col md="12">
              <Card>
                <CardHeader className="border-0">
                  <Row>
                    <Col xs="8">
                      <h3 className="mb-0">{name ? name : 'Data Table'}</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                    </Col>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive striped>
                  <thead className="thead-light">
                    <tr>
                      { data.headers.map((header, idx) => (
                        <th className="sort" data-sort={header.name} scope="col" key={idx}>
                          {header.display}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="list">
                    { data.rows.map((row, idx) => (
                      <tr key={idx}>
                        <td className={data.headers[0].name}>{row[data.headers[0].name]}</td>
                        <td className={data.headers[1].name}>{row[data.headers[1].name]}</td>
                        <td className={data.headers[2].name}>{row[data.headers[2].name]}</td>
                        <td className={data.headers[3].name}>{row[data.headers[3].name]}</td>
                        <td className={data.headers[3].name}>{row[data.headers[4].name]}</td>
                        <td className={data.headers[3].name}>{row[data.headers[5].name]}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        }
      </>
    );
  }

  // Return rener query
  return renderFromQuery;
}

// Report Table Component
class ReportTable extends Component {
  // On mount
  componentDidMount() {

  }

  // Render
  render() {

    // Get props
    const { report, filters, index, cubejsApi } = this.props;
    console.log("Table: ", this.props);

    // result
    return (
      <>
        { report &&
          <QueryRenderer query={generateQuery(report, filters)}
                         cubejsApi={cubejsApi}
                         report={report}
                         filters={filters}
                         render={render(report.name, index)} />
        }
      </>
    )
  }
}

// Connect to store
const ConnectedReportTable = connect(state => ({
  profile: state.profile,
  filters: state.report.filters
}))(ReportTable);

export default ConnectedReportTable;
