/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Imports
import Sample from "views/pages/Sample.js";

// Auth
import Login from "views/auth/Login.js";
import Register from "views/auth/Register.js";

// Account Home
import Home from "views/home";

// Knowledge
import Behaviors from "views/knowledge/behaviors";
import Personas from "views/knowledge/personas";
import PersonaModel from "views/knowledge/personas/view";
import Agent from "views/knowledge/agent";
import Segments from "views/knowledge/segments";
import SegmentModel from "views/knowledge/segments/view";

// Insights
import Customers from "views/insights/customers";
import ViewCustomer from "views/insights/customers/view";
import Campaigns from "views/insights/campaigns";
import ViewCampaign from "views/insights/campaigns/view";
import Reports from "views/insights/reports";
import ViewReport from "views/insights/reports/view"

// Settings
import Clients from "views/settings/clients";

// Client Specific
import Members from "views/insights/members";

const routes = [
  {
    collapse: true,
    name: "Customers",
    display: true,
    icon: "ni ni-single-02 text-primary",
    state: "knowledgeCollapse",
    views: [
      {
        path: "/home",
        display: true,
        name: "Home",
        miniName: "H",
        component: Home,
        layout: "/admin",
      },
      {
        path: "/knowledge/behaviors",
        display: true,
        name: "Behaviors",
        miniName: "B",
        component: Behaviors,
        layout: "/admin",
      },
      {
        path: "/knowledge/personas/view/:id",
        display: false,
        component: PersonaModel,
        layout: "/admin",
      },
      {
        path: "/knowledge/personas",
        display: true,
        name: "Personas",
        miniName: "P",
        component: Personas,
        layout: "/admin",
      },
      {
        path: "/knowledge/segments/view/:id",
        display: false,
        component: SegmentModel,
        layout: "/admin",
      },
      {
        path: "/knowledge/segments",
        display: true,
        name: "Segments",
        miniName: "S",
        component: Segments,
        layout: "/admin",
      },
      {
        path: "/knowledge/agent",
        display: false,
        name: "Agent",
        miniName: "A",
        component: Agent,
        layout: "/admin",
      }
    ],
  },
  {
    collapse: true,
    name: "Apps",
    display: true,
    icon: "ni ni-app text-primary",
    state: "insightsCollapse",
    views: [
      // {
      //   path: "/insights/customers",
      //   display: true,
      //   name: "Customers",
      //   miniName: "C",
      //   component: Customers,
      //   layout: "/admin",
      // },
      {
        path: "/insights/customers/view/:query",
        display: false,
        component: ViewCustomer,
        layout: "/admin",
      },
      {
        path: "/insights/campaigns/view/:query",
        display: false,
        component: ViewCampaign,
        layout: "/admin",
      },
      {
        path: "/insights/campaigns",
        display: true,
        name: "Facebook Ads",
        miniName: "F",
        component: Campaigns,
        layout: "/admin",
      },
      // {
      //   path: "/insights/reports/view/:query",
      //   display: false,
      //   component: ViewReport,
      //   layout: "/admin",
      // },
      // {
      //   path: "/insights/reports",
      //   display: true,
      //   name: "Reports",
      //   miniName: "R",
      //   component: Reports,
      //   layout: "/admin",
      // }
    ],
  },

  // {
  //   collapse: true,
  //   name: "Apps",
  //   display: true,
  //   icon: "ni ni-app text-primary",
  //   state: "appsCollapse",
  //   views: [
  //     {
  //       path: "/apps/pixel",
  //       display: true,
  //       name: "Pixel",
  //       miniName: "P",
  //       component: Sample,
  //       layout: "/admin",
  //     }
  //   ],
  // },
  {
    collapse: true,
    name: "Settings",
    display: true,
    icon: "ni ni-settings-gear-65 text-primary",
    state: "settingsCollapse",
    views: [
      // {
      //   path: "/apps/pixel",
      //   display: true,
      //   name: "Pixel",
      //   miniName: "P",
      //   component: Sample,
      //   layout: "/admin",
      // },
      {
        path: "/knowledge/clients",
        display: true,
        name: "Clients",
        miniName: "C",
        component: Clients,
        layout: "/admin",
      },
      {
        path: "/account",
        display: true,
        name: "Account",
        miniName: "A",
        component: Sample,
        layout: "/admin",
      }
    ],
  },
  {
    collapse: true,
    name: "Examples",
    display: false,
    icon: "ni ni-ungroup text-orange",
    state: "examplesCollapse",
    views: [
      {
        path: "/login",
        display: false,
        name: "Login",
        miniName: "L",
        component: Login,
        layout: "/auth",
      },
      {
        path: "/register",
        display: false,
        name: "Register",
        miniName: "R",
        component: Register,
        layout: "/auth",
      }
    ],
  },
];

export default routes;
