/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// React imports
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// nodejs library to set properties for components
import PropTypes from "prop-types";

// node.js library that concatenates classes (strings)
import classnames from "classnames";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Row,
  Col,
  ListGroupItem,
  ListGroup,
  Progress,
  Button
} from "reactstrap";

// javascipt plugin for creating charts
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';

// react plugin used to create charts
import { Line } from 'react-chartjs-2';

// Fake data
import { faker } from '@faker-js/faker';

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top'
    },
    title: {
      display: true,
      text: 'Chart.js Line Chart',
    },
  },
  maintainAspectRatio: false
};

const labels = ['1/1', '1/8', '1/15', '1/22', '1/29', '2/5'];

export const data = {
  labels,
  datasets: [
    {
      fill: true,
      label: 'Dataset 2',
      data: labels.map(() => faker.datatype.number({ min: 1000, max: 2000 })),
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
};

const personas = [
  {
    id: "entity-1",
    name: "Business Casual",
    total: 100,
    value: "$2,500",
    strength: 80,
    description: "They are looking for a stylish and versatile shirt that can be worn to the office or on a night out."
  },
  {
    id: "entity-2",
    name: "Tech-savvy Millennials",
    total: 100,
    value: "$2,500",
    strength: 60,
    description: "They are tech-savvy, socially conscious, and health-conscious. They prioritize style, comfort, and functionality when making a purchase."
  },
  {
    id: "entity-3",
    name: "Trendsetters",
    total: 100,
    value: "$2,500",
    strength: 30,
    description: "Outdoor enthusiasts or travelers who want to be prepared for any potential health issues while they are away from home."
  },
  {
    id: "entity-4",
    name: "Fitness Enthusiasts",
    total: 100,
    value: "$2,500",
    strength: 30,
    description: "Parents with young children who want to have important medical information readily available in case of an emergency."
  },
  {
    id: "entity-5",
    name: "Professional Athletes",
    total: 100,
    value: "$2,500",
    strength: 30,
    description: "People who live alone or are frequently away from family and friends who would need to be informed in case of an emergency."
  }
];

// const personas = [
//   {
//     id: "entity-1",
//     name: "Seniors & Elderly Individuals",
//     total: 100,
//     value: "$2,500",
//     strength: 80,
//     description: "Seniors and elderly individuals who want to ensure they receive appropriate medical care in case of an emergency"
//   },
//   {
//     id: "entity-2",
//     name: "Chronic Health Conditions",
//     total: 100,
//     value: "$2,500",
//     strength: 60,
//     description: "People with chronic health conditions or disabilities who want to make sure their medical history and important information is readily available in case of an emergency."
//   },
//   {
//     id: "entity-3",
//     name: "Outdoor Enthusiasts",
//     total: 100,
//     value: "$2,500",
//     strength: 30,
//     description: "Outdoor enthusiasts or travelers who want to be prepared for any potential health issues while they are away from home."
//   },
//   {
//     id: "entity-4",
//     name: "Parents with Young Children",
//     total: 100,
//     value: "$2,500",
//     strength: 30,
//     description: "Parents with young children who want to have important medical information readily available in case of an emergency."
//   },
//   {
//     id: "entity-5",
//     name: "Lone Travelers",
//     total: 100,
//     value: "$2,500",
//     strength: 30,
//     description: "People who live alone or are frequently away from family and friends who would need to be informed in case of an emergency."
//   }
// ];

function CampaignsChart({ profile, account, knowledge, model, report }) {

  return (
    <Row>
      <Col md="12">
        <Card className="bg-primary">
          <CardHeader className="bg-transparent">
            <Row className="align-items-center">
              <div className="col">
                <h6 className="text-light text-uppercase ls-1 mb-1">
                  Customer Intelligence
                </h6>
                <h5 className="h3 text-white mb-0">Campaign Performance</h5>
              </div>
              <div className="col">

              </div>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="12" xl="4">
                <Card className={`card-stats text-white bg-white`}>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className={`text-uppercase text-muted mb-0 text-primary`}
                        >
                          Total Sales
                        </CardTitle>
                        <span className={`h2 font-weight-bold mb-0`}>$61,284.59</span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md="12" xl="4">
                <Card className={`card-stats text-white bg-white`}>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className={`text-uppercase text-muted mb-0 text-primary`}
                        >
                          Total Orders
                        </CardTitle>
                        <span className={`h2 font-weight-bold mb-0`}>295</span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md="12" xl="4">
                <Card className={`card-stats text-white bg-white`}>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className={`text-uppercase text-muted mb-0 text-primary`}
                        >
                          Time to Purchase
                        </CardTitle>
                        <span className={`h2 font-weight-bold mb-0`}>8.9 Days</span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row style={{
              height: 300
            }}>
              <Col xl="3">
                <ListGroup className="list my--3 bg-primary" flush>
                  { personas.map((persona, index) => (
                    <ListGroupItem className="bg-primary border-0 px-0">
                      <Row className="align-items-center">
                        <div className="col">
                          <h5 className="text-white">{persona.name}</h5>
                          <Progress
                            className="progress-xs mb-0"
                            color="info"
                            max="100"
                            value={persona.strength}
                          />
                        </div>
                      </Row>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </Col>
              <Col xl="9">
                <div style={{
                  height: '100%'
                }}>
                  <Line
                    options={options}
                    data={data}
                  />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

// Connect to store
const ConnectedCampaignChart = connect(state => ({
  profile: state.profile,
  account: state.account,
  knowledge: state.knowledge,
  model: state.model,
  report: state.report
}))(CampaignsChart);

export default ConnectedCampaignChart;
