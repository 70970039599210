/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

function KnowledgeHeader({ headline, description }) {
  return (
    <>
      <div className="header bg-info pb-6">
        <span className="mask bg-gradient-info opacity-3" />

        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col lg="12" md="10">
              <h1 className="display-2 text-white">{headline}</h1>
              <p className="text-white mt-0 mb-5">
                {description}
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default KnowledgeHeader;
