/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/

// React
import React, { Component } from 'react';
import { connect } from 'react-redux';

// nodejs library to set properties for components
import PropTypes from "prop-types";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Form,
  FormGroup,
  Label,
  Input,
  ListGroupItem,
  ListGroup,
  Media,
  NavItem,
  NavLink,
  Nav,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Spinner
} from "reactstrap";

// Account config
import config from "config";

// CubeJs
import cubejs from '@cubejs-client/core';
import { QueryRenderer } from '@cubejs-client/react';

function abbrState(input, to){

    var states = [
        ['Arizona', 'AZ'],
        ['Alabama', 'AL'],
        ['Alaska', 'AK'],
        ['Arkansas', 'AR'],
        ['California', 'CA'],
        ['Colorado', 'CO'],
        ['Connecticut', 'CT'],
        ['Delaware', 'DE'],
        ['Florida', 'FL'],
        ['Georgia', 'GA'],
        ['Hawaii', 'HI'],
        ['Idaho', 'ID'],
        ['Illinois', 'IL'],
        ['Indiana', 'IN'],
        ['Iowa', 'IA'],
        ['Kansas', 'KS'],
        ['Kentucky', 'KY'],
        ['Louisiana', 'LA'],
        ['Maine', 'ME'],
        ['Maryland', 'MD'],
        ['Massachusetts', 'MA'],
        ['Michigan', 'MI'],
        ['Minnesota', 'MN'],
        ['Mississippi', 'MS'],
        ['Missouri', 'MO'],
        ['Montana', 'MT'],
        ['Nebraska', 'NE'],
        ['Nevada', 'NV'],
        ['New Hampshire', 'NH'],
        ['New Jersey', 'NJ'],
        ['New Mexico', 'NM'],
        ['New York', 'NY'],
        ['North Carolina', 'NC'],
        ['North Dakota', 'ND'],
        ['Ohio', 'OH'],
        ['Oklahoma', 'OK'],
        ['Oregon', 'OR'],
        ['Pennsylvania', 'PA'],
        ['Rhode Island', 'RI'],
        ['South Carolina', 'SC'],
        ['South Dakota', 'SD'],
        ['Tennessee', 'TN'],
        ['Texas', 'TX'],
        ['Utah', 'UT'],
        ['Vermont', 'VT'],
        ['Virginia', 'VA'],
        ['Washington', 'WA'],
        ['West Virginia', 'WV'],
        ['Wisconsin', 'WI'],
        ['Wyoming', 'WY'],
    ];

    if (to == 'abbr'){
        input = input.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
        for(var i = 0; i < states.length; i++){
            if(states[i][0] == input){
                return(states[i][1]);
            }
        }
    } else if (to == 'name'){
        input = input.toUpperCase();
        for(var i = 0; i < states.length; i++){
            if(states[i][1] == input){
                return(states[i][0]);
            }
        }
    }
}

// Pre-ready queries
function generateQuery(report, filters) {
  return {
    "dimensions": [
      "Members.state"
    ],
    "order": {
      "Members.totalMembers": "desc"
    },
    "measures": [
      "Members.totalMembers"
    ],
    "timeDimensions": [
      {
        "dimension": "Members.createdAt",
        "dateRange": "Last 7 days"
      }
    ]
  };
}

function render() {
  // Wrapper for query for all data
  function renderFromQuery({ resultSet, error }) {
    // Format data
    let data = [];
    let states = [];

    // State map
    let stateMap = {};
    let total = 0;

    // Parse result set
    if(resultSet && resultSet.loadResponse.results.length > 0) {
      // Get first row results
      const results = resultSet.loadResponse.results[0].data;

      // Populate data
      for(var i = 0; i < results.length; i++) {
        // Convert to state
        let state = (results[i]['Members.state'].length <= 2) ? abbrState(results[i]['Members.state'].toUpperCase(), 'name') : results[i]['Members.state'];

        // Update total
        total += results[i]['Members.totalMembers'];

        // Update states
        if(stateMap.hasOwnProperty(state)) {
          // Increment
          stateMap[state] += results[i]['Members.totalMembers'];
        } else {
          // Set
          if(state) {
              stateMap[state] = results[i]['Members.totalMembers'];
          }
        }
      }

      // Populate map
      _.forOwn(stateMap, function(count, state) {
        // Opulate states
        states.push({
          name: state,
          total: 100.0 * count / total
        });
      });

      // Extract data
      data = _.orderBy(states, ['total'], ['desc']).slice(0, 5);
    }
    console.log("Member Growth - Locations: ", stateMap, data);

    // Return formatted table
    return (
      <>
        { data.length < 1 ?
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          :
          <ListGroup className="list my--3 bg-primary" flush>
            { data.map((state, index) => (
              <ListGroupItem className="bg-primary border-0 px-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h5 className="text-white">{state.name}</h5>
                    <Progress
                      className="progress-xs mb-0"
                      color="info"
                      max="100"
                      value={state.total}
                    />
                  </div>
                </Row>
              </ListGroupItem>
            ))}
          </ListGroup>
        }
      </>
    );
  }

  // Return rener query
  return renderFromQuery;
}

// Report Table Component
class MemberGrowthLocations extends Component {

  // On mount
  constructor(props) {
    super(props);
  }

  // Render
  render() {
    // Get props
    const { filters, index, cubejsApi } = this.props;

    // result
    return (
      <QueryRenderer query={generateQuery(filters)}
                     cubejsApi={cubejsApi}
                     filters={filters}
                     render={render()} />
    )
  }
}

// Connect to store
const ConnectedMemberGrowthLocations = connect(state => ({
  profile: state.profile,
  filters: state.report.filters
}))(MemberGrowthLocations);

export default ConnectedMemberGrowthLocations;
