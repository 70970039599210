// action - state management
import * as actionTypes from '../actions';

const initialState = {
    initialized: false,

    // User behaviors
    behaviors: [
      {
        id: "behavior-1",
        name: "Email Sign-Ups",
        total: 960,
        value: "$294,389.23",
        strength: 80,
        description: "..."
      },
      {
        id: "behavior-2",
        name: "Add to Cart",
        total: 1953,
        value: "$420,328.79",
        strength: 80,
        description: "..."
      },
      {
        id: "behavior-3",
        name: "Initiated Checkout",
        total: 1584,
        value: "$340,911.83",
        strength: 80,
        description: "..."
      },
      {
        id: "behavior-4",
        name: "Online Purchases",
        total: 1040,
        value: "$223,831.36",
        strength: 80,
        description: "..."
      }
    ],
    behaviorDomains: [
      {
        name: "Commerce",
        id: "@axon/commerce",
        display: "2 Data Sources",
      },
      {
        name: "Marketing",
        id: "@axon/marketing",
        display: "1 Data Source"
      }
    ],
    selectedBehaviorDomain: {
      name: "Commerce",
      id: "@axon/commerce"
    },

    // Workflow
    domains: [
      {
        name: "Maximize Customer Lifetime Value",
        id: "@axon/commerce/customers",
        display: "1 Segment",
      },
      {
        name: "Maximize Return on Ad Spend",
        id: "@axon/marketing/campaigns",
        display: "0 Segments"
      },
      {
        name: "Minimize Customer Acquisition Cost",
        id: "@axon/commerce/products",
        display: "0 Segments"
      }
    ],
    // domains: [
    //   {
    //     name: "Active",
    //     id: "@axon/commerce/customers",
    //     display: "91 Customers",
    //   },
    //   {
    //     name: "Staged",
    //     id: "@axon/marketing/campaigns",
    //     display: "6 Customers"
    //   },
    //   {
    //     name: "Review",
    //     id: "@axon/commerce/products",
    //     display: "12 Customers"
    //   },
    //   {
    //     name: "In-Progress",
    //     id: "@axon/commerce/orders",
    //     display: "10 Customers"
    //   }
    // ],
    selectedDomain: {
      name: "Customer Personas",
      id: "@axon/commerce/customers"
    },
    segments: [
      {
        id: "segment-1",
        name: "Established Business Professionals",
        total: 58,
        value: "$327,493.93",
        strength: 80,
        description: "Our target customer segment is composed of tech-savvy, stylish, and socially conscious individuals between the ages of 40 and 60. This group is primarily composed of lawyers, C-level executives, and business owners who prioritize style, comfort, and functionality when making a purchase. They are looking for a versatile shirt that can be worn to the office or on a night out"
      }
    ],
    factors: [
      {
        id: "objective-factor-1",
        name: "Customer Lifetime Value",
        total: 58,
        value: "$987.34",
        strength: 60,
        segments: 1,
        description: ""
      },
      {
        id: "objective-factor-2",
        name: "Time to First Purchase",
        total: 58,
        value: "$987.34",
        strength: 25,
        segments: 1,
        description: "They are looking for a stylish and versatile shirt that can be worn to the office or on a night out."
      }
    ],
    entities: [
      {
        id: "entity-1",
        name: "Business Casual",
        total: 58,
        value: "$987.34",
        strength: 80,
        segments: 1,
        description: "They are looking for a stylish and versatile shirt that can be worn to the office or on a night out.",
        persona: "You are a business casual buying persona for a clothing retailer named Teddy Stratford that sells athletic fit button down shirts that zip.\nYou shop at this retailer looking for a stylish and versatile shirt that can be worn to the office or on a night out.\nYou are likely a male between the age of 30 and 55.\nYou likely live in an urban coastal city.\nYou likely have one of the following job functions:\n- Principal\n- Lawyer\n- Chief Executive Officer\n- Chief Technology Officer"
      },
      {
        id: "entity-2",
        name: "Tech-savvy Millennials",
        total: 22,
        value: "$784.34",
        strength: 60,
        segments: 1,
        description: "They are tech-savvy, socially conscious, and health-conscious. They prioritize style, comfort, and functionality when making a purchase.",
        persona: "You are a business casual buying persona for a clothing retailer named Teddy Stratford that sells athletic fit button down shirts that zip.\nYou shop at this retailer looking for a stylish and versatile shirt that can be worn to the office or on a night out.\nYou are likely a male between the age of 30 and 55.\nYou likely live in an urban coastal city.\nYou likely have one of the following job functions:\n- Principal\n- Lawyer\n- Chief Executive Officer\n- Chief Technology Officer"
      },
      {
        id: "entity-3",
        name: "Trendsetters",
        total: 8,
        value: "$345.98",
        strength: 30,
        segments: 0,
        description: "They are fashion-forward individuals who enjoy staying up-to-date with the latest trends and styles.",
        persona: "You are a business casual buying persona for a clothing retailer named Teddy Stratford that sells athletic fit button down shirts that zip.\nYou shop at this retailer looking for a stylish and versatile shirt that can be worn to the office or on a night out.\nYou are likely a male between the age of 30 and 55.\nYou likely live in an urban coastal city.\nYou likely have one of the following job functions:\n- Principal\n- Lawyer\n- Chief Executive Officer\n- Chief Technology Officer"
      },
      {
        id: "entity-4",
        name: "Fitness Enthusiasts",
        total: 3,
        value: "$2,500",
        strength: 30,
        segments: 0,
        description: "They are into physical fitness and like to stay active through gym workouts, sports or outdoor activities.",
        persona: "You are a business casual buying persona for a clothing retailer named Teddy Stratford that sells athletic fit button down shirts that zip.\nYou shop at this retailer looking for a stylish and versatile shirt that can be worn to the office or on a night out.\nYou are likely a male between the age of 30 and 55.\nYou likely live in an urban coastal city.\nYou likely have one of the following job functions:\n- Principal\n- Lawyer\n- Chief Executive Officer\n- Chief Technology Officer"
      },
      {
        id: "entity-5",
        name: "Professional Athletes",
        total: 0,
        value: "$0.0",
        strength: 0,
        segments: 0,
        description: "Professional athletes need functional and stylish athletic wear for their sport and training.",
        persona: "You are a business casual buying persona for a clothing retailer named Teddy Stratford that sells athletic fit button down shirts that zip.\nYou shop at this retailer looking for a stylish and versatile shirt that can be worn to the office or on a night out.\nYou are likely a male between the age of 30 and 55.\nYou likely live in an urban coastal city.\nYou likely have one of the following job functions:\n- Principal\n- Lawyer\n- Chief Executive Officer\n- Chief Technology Officer"
      }
    ],
    groupings: []
};

// ===========================|| PRODUCTS REDUCER ||=========================== //

const knowledgeReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INIITIALIZE_KNOWLEDGE: {
          console.log("Initializing knowledge...", action.payload);

          // Update state
          return {
              ...state,
              initialized: true
          };
        }
        case actionTypes.SET_ACTIVE_DOMAIN: {
          console.log("Setting active domain...", action.payload);

          // Update state
          return {
              ...state,
              selectedDomain: action.payload
          };
        }
        default:
            return state;
    }
};

export default knowledgeReducer;

/*

{
  id: "entity-1",
  name: "Business Casual",
  total: 100,
  value: "$2,500",
  strength: 80,
  description: "They are looking for a stylish and versatile shirt that can be worn to the office or on a night out."
},
{
  id: "entity-2",
  name: "Tech-savvy Millennials",
  total: 100,
  value: "$2,500",
  strength: 60,
  description: "They are tech-savvy, socially conscious, and health-conscious. They prioritize style, comfort, and functionality when making a purchase."
},
{
  id: "entity-3",
  name: "Trendsetters",
  total: 100,
  value: "$2,500",
  strength: 30,
  description: "They are fashion-forward individuals who enjoy staying up-to-date with the latest trends and styles."
},
{
  id: "entity-4",
  name: "Fitness Enthusiasts",
  total: 100,
  value: "$2,500",
  strength: 30,
  description: "They are into physical fitness and like to stay active through gym workouts, sports or outdoor activities."
},
{
  id: "entity-5",
  name: "Professional Athletes",
  total: 100,
  value: "$2,500",
  strength: 30,
  description: "Professional athletes need functional and stylish athletic wear for their sport and training."
}


*/
