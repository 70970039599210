// Setup config export
const config = {
  persona: {
    chat: {
      url: "https://persona-zysc.vercel.app"
      // url: "http://localhost:5400"
    }
  },
};

export default config;
