/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

// node.js library that concatenates classes (strings)
import classnames from "classnames";

// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";

// react plugin used to create DropdownMenu for selecting items
import Select2 from "react-select2-wrapper";

// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";

// Sparklines
import { Sparklines, SparklinesLine, SparklinesBars, SparklinesSpots, SparklinesNormalBand } from 'react-sparklines';

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Form,
  FormGroup,
  Label,
  Input,
  ListGroupItem,
  ListGroup,
  Media,
  NavItem,
  NavLink,
  Nav,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Spinner
} from "reactstrap";

// Formatting
import accounting from 'accounting-js';

// core components
import KnowledgeHeader from "components/Headers/KnowledgeHeader.js";

// Auth actions
import {
  INIITIALIZE_INSIGHTS,
  SET_ACTIVE_REPORT,
  SET_FILTER_TIMEFRAME
} from 'store/actions';

// Import report blocks
import CampaignsChart from "./components/CampaignsChart.js";

function ViewCampaign({ profile, account, knowledge, insights, report, model, dispatch }) {
  console.log("Account:", account);
  console.log("Knowledge:", knowledge);
  console.log("Insights: ", insights);
  console.log("Report:", report);

  // Get history
  const history = useHistory();

  // States (Report)
  const [startDate, setStartDate] = React.useState(report.filters.timeframe.startDate);
  const [endDate, setEndDate] = React.useState(report.filters.timeframe.endDate);
  const [activeNav, setActiveNav] = React.useState(1);
  const listRef = React.useRef(null);

  // Sync insights
  useEffect(() => {

  }, []);

  const handleReactDatetimeChange = (who, date) => {
    // Format date
    const formattedDate = date.format('YYYY-MM-DD');
    console.log("Updating date: ", who, formattedDate);

    // Set startDate
    if(who == "startDate") {
      if(startDate > endDate) {
        // Create new end date
        let newEndDate = startDate.add('days', 7).format('YYYY-MM-DD');
        setEndDate(formattedDate);
      }

      // Set start date
      setStartDate(formattedDate);
    }

    // Set startDate
    if(who == "endDate") {
      setEndDate(formattedDate);
    }

    // Update timeframe
    dispatch({
      type: SET_FILTER_TIMEFRAME,
      payload: {
        startDate: startDate,
        endDate: endDate
      }
    });
  };

  const getClassNameReactDatetimeDays = (date) => {
    if (startDate && endDate) {
    }
    if (startDate && endDate && startDate._d + "" !== endDate._d + "") {
      if (
        new Date(endDate._d + "") > new Date(date._d + "") &&
        new Date(startDate._d + "") < new Date(date._d + "")
      ) {
        return " middle-date";
      }
      if (endDate._d + "" === date._d + "") {
        return " end-date";
      }
      if (startDate._d + "" === date._d + "") {
        return " start-date";
      }
    }
    return "";
  };

  return (
    <>
      <KnowledgeHeader
        headline="Facebook Ads"
        description=""
      />
      <Container className="mt--6" fluid>
        <CampaignsChart />
        <Row>
          <Col xl="12">
            <Row>
              <Col xl="4">
                <Card
                  className="bg-primary border-0"
                  style={{
                    height: 140
                  }}
                >
                  <CardBody>
                    <Form>
                      <Row className="input-daterange datepicker align-items-center">
                        <Col xs={12} sm={6}>
                          <label className="text-white form-control-label">
                            Start Date
                          </label>
                          <FormGroup>
                            <ReactDatetime
                              inputProps={{
                                placeholder: "Date Picker Here",
                              }}
                              value={startDate}
                              timeFormat={false}
                              onChange={(e) =>
                                handleReactDatetimeChange("startDate", e)
                              }
                              renderDay={(props, currentDate, selectedDate) => {
                                let classes = props.className;
                                classes +=
                                  getClassNameReactDatetimeDays(currentDate);
                                return (
                                  <td {...props} className={classes}>
                                    {currentDate.date()}
                                  </td>
                                );
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} sm={6}>
                          <FormGroup>
                            <label className="text-white form-control-label">
                              End Date
                            </label>
                            <ReactDatetime
                              inputProps={{
                                placeholder: "Date Picker Here",
                              }}
                              value={endDate}
                              timeFormat={false}
                              onChange={(e) =>
                                handleReactDatetimeChange("endDate", e)
                              }
                              renderDay={(props, currentDate, selectedDate) => {
                                let classes = props.className;
                                classes +=
                                  getClassNameReactDatetimeDays(currentDate);
                                return (
                                  <td {...props} className={classes}>
                                    {currentDate.date()}
                                  </td>
                                );
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="8">
                <Card
                  className="bg-gradient-primary border-0"
                  style={{
                    height: 140
                  }}
                >
                  <CardBody>
                    <Row>
                      <Col md="12">
                        <Input
                          className="form-control"
                          type="text"
                          placeholder="Ask a questions about these campaigns..."
                          style={{
                            zIndex: 100000000,
                            position: 'absolute',
                            width: '90%',
                            top: 5
                          }}
                          onChange={() => { console.log("changing...")}}
                        />
                        <div
                          className="icon icon-shape bg-white text-dark rounded-circle shadow"
                          style={{
                            zIndex: 200000000,
                            position: 'absolute',
                            right: '2%',
                            top: -2,
                            height: 80,
                            width: 80
                          }}
                        >
                          <i className="ni ni-books" />
                        </div>
                      </Col>
                      <Col
                        md="12"
                        style={{
                          paddingTop: 65
                        }}
                      >
                        <Button onClick={() => { console.log("selected customer"); }} color="default" size="sm" type="button">
                          top performers
                        </Button>
                        <Button onClick={() => { console.log("selected customer"); }} color="default" size="sm" type="button">
                          top performers
                        </Button>
                        <Button onClick={() => { console.log("selected customer"); }} color="default" size="sm" type="button">
                          new and noteworthy customers
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <Row>
                  <Col md="12" xl={3}>
                    <Card className={`card-stats`}>
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className={`text-uppercase text-muted mb-0`}
                            >
                              Return on Ad Spend
                            </CardTitle>
                            <span className={`h2 font-weight-bold mb-0`}>2.34</span>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="12" xl={3}>
                    <Card className={`card-stats`}>
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className={`text-uppercase text-muted mb-0`}
                            >
                              Total Sales
                            </CardTitle>
                            <span className={`h2 font-weight-bold mb-0`}>$72,593.34</span>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="12" xl={3}>
                    <Card className={`card-stats`}>
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className={`text-uppercase text-muted mb-0`}
                            >
                              Total Orders
                            </CardTitle>
                            <span className={`h2 font-weight-bold mb-0`}>294</span>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="12" xl={3}>
                    <Card className={`card-stats`}>
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className={`text-uppercase text-muted mb-0`}
                            >
                              Days to Purchase
                            </CardTitle>
                            <span className={`h2 font-weight-bold mb-0`}>3.4</span>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Card>
                      <CardHeader className="border-0">
                        <Row>
                          <Col xs="8">
                            <h3 className="mb-0">Campaign Breakdown</h3>
                          </Col>
                          <Col className="text-right" xs="4">
                          </Col>
                        </Row>
                      </CardHeader>
                      <Table className="align-items-center table-flush" responsive striped>
                        <thead className="thead-light">
                          <tr>
                            <th className="sort" data-sort="name" scope="col">
                              Campaign Name
                            </th>
                            <th className="sort" data-sort="return_on_ad_spend" scope="col">
                              Return on Ad Spend
                            </th>
                            <th className="sort" data-sort="sales" scope="col">
                              Total Sales
                            </th>
                            <th className="sort" data-sort="spend" scope="col">
                              Total Spend
                            </th>
                            <th className="sort" data-sort="projected_roi" scope="col">
                              Projected ROI
                            </th>
                            <th/>
                          </tr>
                        </thead>
                        <tbody className="list">
                          <tr>
                            <td className="name">[CF] Prospecting - Conversion</td>
                            <td className="text-center return_on_ad_spend">1.96</td>
                            <td className="text-center sales">$45,611.70</td>
                            <td className="text-center spend">$23,304.63</td>
                            <td className="text-center projected_roi">5.8</td>
                            <td className="text-right">
                              <Button onClick={() => { history.push("/admin/insights/campaigns/view/1") }} color="primary" size="md" type="button" style={{
                                width: 120
                              }}>
                                View
                              </Button>
                            </td>
                          </tr>
                          <tr>
                            <td className="name">[CF] Retargeting - Conversion</td>
                            <td className="text-center return_on_ad_spend">3.07</td>
                            <td className="text-center sales">$4,668.80</td>
                            <td className="text-center spend">$1,562.35</td>
                            <td className="text-center projected_roi">8.7</td>
                            <td className="text-right">
                              <Button onClick={() => { console.log("selected customer"); }} color="primary" size="md" type="button" style={{
                                width: 120
                              }}>
                                View
                              </Button>
                            </td>
                          </tr>
                          <tr>
                            <td className="name">[CF] Retention - Conversion</td>
                            <td className="text-center return_on_ad_spend">3.07</td>
                            <td className="text-center sales">$21,152.90</td>
                            <td className="text-center spend">$6,879.80</td>
                            <td className="text-center projected_roi">5.2</td>
                            <td className="text-right">
                              <Button onClick={() => { console.log("selected customer"); }} color="primary" size="md" type="button" style={{
                                width: 120
                              }}>
                                View
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Card>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

// Connect to store
const ConnectedViewCampaign = connect(state => ({
  profile: state.profile,
  account: state.account,
  knowledge: state.knowledge,
  insights: state.insights,
  report: state.report,
  model: state.model
}))(ViewCampaign);

export default ConnectedViewCampaign;
