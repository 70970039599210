/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

// node.js library that concatenates classes (strings)
import classnames from "classnames";

// javascipt plugin for creating charts
import { Chart } from "chart.js";

// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";

// Sparklines
import { Sparklines, SparklinesLine, SparklinesBars, SparklinesSpots, SparklinesNormalBand } from 'react-sparklines';

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  Media,
  NavItem,
  NavLink,
  Nav,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import KnowledgeHeader from "components/Headers/KnowledgeHeader.js";

// Auth actions
import {
  INIITIALIZE_KNOWLEDGE,
  SET_ACTIVE_DOMAIN,
  SET_ACTIVE_ENTITY
} from 'store/actions';

import {
  chartOptions,
  parseOptions,
  chartExample1
} from "variables/charts.domains.js";

function boxMullerRandom () {
    let phase = false,
        x1, x2, w, z;

    return (function() {

        if (phase = !phase) {
            do {
                x1 = 2.0 * Math.random() - 1.0;
                x2 = 2.0 * Math.random() - 1.0;
                w = x1 * x1 + x2 * x2;
            } while (w >= 1.0);

            w = Math.sqrt((-2.0 * Math.log(w)) / w);
            return x1 * w;
        } else {
            return x2 * w;
        }
    })();
}

// Random data generator
function randomData(n = 30) {
  return Array.apply(0, Array(n)).map(boxMullerRandom);
}

function Segments({ profile, knowledge, dispatch }) {
  console.log("Knowledge:", knowledge);

  // Get history
  const history = useHistory();

  // Handle artwork selection
  const selectEntity = (entity) => {
    // Update local store
    dispatch({
      type: SET_ACTIVE_ENTITY,
      payload: entity
    });

    // Got to review page
    history.push(`/admin/knowledge/segments/view/${entity.id}`);
  }

  return (
    <>
      <KnowledgeHeader
        headline="Customer Segments"
        description="Managed segments are constantly optimized by personas towards target objectives."
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col xl="4">
            <ListGroup>
              { knowledge.domains.map((domain, index) => (
                <ListGroupItem
                  className={`list-group-item-action flex-column align-items-start py-4 px-4`}
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    console.log("select domain")
                  }}
                  active={false}
                  key={index}
                  active={domain.id == knowledge.selectedDomain.id}
                >
                  <Row className="align-items-center">
                    <div className="col ml--2">
                      <h4 className="mb-0">
                        <a href="" onClick={(e) => e.preventDefault()}  className={`${(domain.id == knowledge.selectedDomain.id) ? 'text-white' : ''}`}>
                          {domain.name}
                        </a>
                      </h4>
                      <small>{domain.display}</small>
                    </div>
                    <Col className="col-auto">
                      { (domain.id != knowledge.selectedDomain.id) &&
                        <Button color="primary" size="sm" type="button">
                          View
                        </Button>
                      }
                    </Col>
                  </Row>
                </ListGroupItem>
              ))}
            </ListGroup>
          </Col>
          <Col xl="8">
            <Row>
              <div className="col">
                <Card>
                  <CardHeader className="border-0">
                    <h3 className="mb-0">Active Segments</h3>
                  </CardHeader>

                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th className="sort" data-sort="name" scope="col">
                          Name
                        </th>
                        <th className="sort" data-sort="matched" scope="col">
                          Matched
                        </th>
                        <th className="sort" data-sort="value" scope="col">
                          Annualized Value
                        </th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody className="list">
                      { knowledge.segments.map((entity, index) => (
                        <tr key={index}>
                          <th scope="row">
                            {entity.name}
                          </th>
                          <td className="matched">{entity.total}</td>
                          <td className="value">{entity.value}</td>
                          <td className="text-right">
                            <UncontrolledDropdown group>
                                <Button
                                  color="primary" size="sm" type="button"
                                  onClick={() => {
                                    // select entity
                                    selectEntity(entity);
                                  }}
                                  style={{
                                    width: 120
                                  }}
                                >
                                  View
                                </Button>
                                <DropdownToggle
                                  caret
                                  color="primary"
                                />
                                <DropdownMenu>
                                  <DropdownItem disabled>
                                    Cancel
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  <CardFooter className="py-4">
                  </CardFooter>
                </Card>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

// Connect to store
const ConnectedSegments = connect(state => ({
  profile: state.profile,
  knowledge: state.knowledge
}))(Segments);

export default ConnectedSegments;
