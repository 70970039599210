/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// React imports
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// nodejs library to set properties for components
import PropTypes from "prop-types";

// node.js library that concatenates classes (strings)
import classnames from "classnames";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Row,
  Col,
  ListGroupItem,
  ListGroup,
  Progress,
  Button
} from "reactstrap";

// javascipt plugin for creating charts
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';

// react plugin used to create charts
import { Line } from 'react-chartjs-2';

// Fake data
import { faker } from '@faker-js/faker';

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top'
    },
    title: {
      display: true,
      text: 'Chart.js Line Chart',
    },
  },
  maintainAspectRatio: false
};

const labels = ['Feb 14', 'Feb 21', 'Feb 28', 'March 6', 'March 13', 'March 20', 'March 27'];

export const data = {
  labels,
  datasets: [
    {
      fill: true,
      label: 'Dataset 2',
      data: [495.39, 478.98, 545.39, 535.98, 589.34, 650.02, 625.32, 707.23],
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
};

function ChartBlock({ profile, account, knowledge, model, report }) {


  return (
    <Row>
      <Col md="12">
        <Card className="bg-primary">
          <CardHeader className="bg-transparent">
            <Row className="align-items-center">
              <div className="col">
                <h6 className="text-light text-uppercase ls-1 mb-1">
                  Primary Objective
                </h6>
                <h5 className="h3 text-white mb-0">Customer Lifetime Value</h5>
              </div>
              <div className="col">

              </div>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="12" xl="4">
                <Card className={`card-stats text-white bg-white`}>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className={`text-uppercase text-muted mb-0 text-primary`}
                        >
                          Avg. Lifetime Value (Est.)
                        </CardTitle>
                        <span className={`h2 font-weight-bold mb-0`}>$834.89</span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md="12" xl="4">
                <Card className={`card-stats text-white bg-white`}>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className={`text-uppercase text-muted mb-0 text-primary`}
                        >
                          Cost per Acquisition (Avg.)
                        </CardTitle>
                        <span className={`h2 font-weight-bold mb-0`}>$46.24</span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md="12" xl="4">
                <Card className={`card-stats text-white bg-white`}>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className={`text-uppercase text-muted mb-0 text-primary`}
                        >
                          Time to Purchase
                        </CardTitle>
                        <span className={`h2 font-weight-bold mb-0`}>0.24 Days</span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row style={{
              height: 300
            }}>
              <Col xl="3">
                <ListGroup className="list my--3 bg-primary" flush>
                  { knowledge.factors.map((factor, index) => (
                    <ListGroupItem className="bg-primary border-0 px-0">
                      <Row className="align-items-center">
                        <div className="col">
                          <h5 className="text-white">{factor.name}</h5>
                          <Progress
                            className="progress-xs mb-0"
                            color="secondary"
                            max="100"
                            value={factor.strength}
                          />
                        </div>
                      </Row>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </Col>
              <Col xl="9">
                <div style={{
                  height: '100%'
                }}>
                  <Line
                    options={options}
                    data={data}
                  />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

// Connect to store
const ConnectedChartBlock = connect(state => ({
  profile: state.profile,
  account: state.account,
  knowledge: state.knowledge,
  model: state.model,
  report: state.report
}))(ChartBlock);

export default ConnectedChartBlock;
