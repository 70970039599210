/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/

// React
import React, { Component } from 'react';
import { connect } from 'react-redux';

// nodejs library to set properties for components
import PropTypes from "prop-types";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Form,
  FormGroup,
  Label,
  Input,
  ListGroupItem,
  ListGroup,
  Media,
  NavItem,
  NavLink,
  Nav,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Spinner
} from "reactstrap";

// Account config
import config from "config";

// CubeJs
import cubejs from '@cubejs-client/core';
import { QueryRenderer } from '@cubejs-client/react';

// react plugin used to create charts
import { Line } from 'react-chartjs-2';

export const options = {
  responsive: true,
  scales: {
    yAxes: [{
      ticks: {
        beginAtZero: true,
        min: 0
      }
    }]
  },
  plugins: {
    legend: {
      position: 'top'
    },
    title: {
      display: true,
      text: 'New Member Growth',
    },
  },
  maintainAspectRatio: false
};

// Pre-ready queries
function generateQuery(report, filters) {
  return {
    "measures": [
      "Members.totalMembers"
    ],
    "timeDimensions": [
      {
        "dimension": "Members.createdAt",
        "dateRange": "Last 7 days"
      }
    ],
    "dimensions": [
      "Members.createdDate"
    ],
    "order": {
      "Members.createdDate": "asc"
    }
  };
}

function render() {
  // Wrapper for query for all data
  function renderFromQuery({ resultSet, error }) {
    console.log("-> Results: ", resultSet);

    // Setup
    let labels = [];
    let dailyData = [];

    // Parse result set
    if(resultSet && resultSet.loadResponse.results.length > 0) {
      // Get first row results
      const results = resultSet.loadResponse.results[0].data;
      // Populate data
      for(var i = 0; i < results.length; i++) {
        // Add label
        labels.push(results[i]['Members.createdDate'].slice(0,10));

        // Add data
        dailyData.push(results[i]['Members.totalMembers']);
      }
    }

    // Format data
    let data = {
      labels,
      datasets: [
        {
          fill: true,
          label: 'New Members Added per Day',
          data: dailyData,
          borderColor: 'rgb(176, 59, 112)',
          backgroundColor: 'rgb(238, 235, 241)',
        },
      ],
    };
    console.log("Growth Chart: ", data);

    // Return formatted table
    return (
      <>
        { data.labels.length == 0 ?
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          :
          <div style={{
            height: '100%'
          }}>
            <Line
              options={options}
              data={data}
            />
          </div>
        }
      </>
    );
  }

  // Return rener query
  return renderFromQuery;
}

// Report Table Component
class MemberGrowthChart extends Component {

  // On mount
  constructor(props) {
    super(props);
  }

  // Render
  render() {
    // Get props
    const { filters, index, cubejsApi } = this.props;
    console.log("Member Growth: ", this.props);

    // result
    return (
      <QueryRenderer query={generateQuery(filters)}
                     cubejsApi={cubejsApi}
                     filters={filters}
                     render={render()} />
    )
  }
}

// Connect to store
const ConnectedMemberGrowthChart = connect(state => ({
  profile: state.profile,
  filters: state.report.filters
}))(MemberGrowthChart);

export default ConnectedMemberGrowthChart;
