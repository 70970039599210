/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

// node.js library that concatenates classes (strings)
import classnames from "classnames";

// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";

// react plugin used to create DropdownMenu for selecting items
import Select2 from "react-select2-wrapper";

// javascipt plugin for creating charts
import { Chart } from "chart.js";

// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";

// Sparklines
import { Sparklines, SparklinesLine, SparklinesBars, SparklinesSpots, SparklinesNormalBand } from 'react-sparklines';

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Form,
  FormGroup,
  Label,
  Input,
  ListGroupItem,
  ListGroup,
  Media,
  NavItem,
  NavLink,
  Nav,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Spinner
} from "reactstrap";

// Formatting
import accounting from 'accounting-js';

// core components
import KnowledgeHeader from "components/Headers/KnowledgeHeader.js";

// Auth actions
import {
  INIITIALIZE_INSIGHTS,
  SET_ACTIVE_REPORT
} from 'store/actions';

// import {
//   chartOptions,
//   parseOptions,
//   chartExample1,
//   chartExample2,
// } from "variables/charts.js";
//
// function boxMullerRandom () {
//     let phase = false,
//         x1, x2, w, z;
//
//     return (function() {
//
//         if (phase = !phase) {
//             do {
//                 x1 = 2.0 * Math.random() - 1.0;
//                 x2 = 2.0 * Math.random() - 1.0;
//                 w = x1 * x1 + x2 * x2;
//             } while (w >= 1.0);
//
//             w = Math.sqrt((-2.0 * Math.log(w)) / w);
//             return x1 * w;
//         } else {
//             return x2 * w;
//         }
//     })();
// }
//
// // Random data generator
// function randomData(n = 30) {
//   return Array.apply(0, Array(n)).map(boxMullerRandom);
// }

function Reports({ profile, account, insights, report, dispatch }) {
  console.log("Account:", account);
  console.log("Insights: ", insights);
  console.log("Report:", report);

  // Get history
  const history = useHistory();

  // States (Report)
  const [startDate, setStartDate] = React.useState(report.filters.timeframe.startDate);
  const [endDate, setEndDate] = React.useState(report.filters.timeframe.endDate);
  const [activeNav, setActiveNav] = React.useState(1);
  const listRef = React.useRef(null);

  // Sync insights
  useEffect(() => {
    if (account.initialized && !insights.initialized) {
      // Initialize insights
      dispatch({
        type: INIITIALIZE_INSIGHTS,
        payload: {
          filters: {
            timeframe: {
              startDate: "",
              endDate: ""
            },
            clients: {
              list: account.clients,
              selected: (account.clients.length > 0) ? account.clients[0] : null
            }
          }
        }
      });
    }
  }, [ account ]);

  const handleReactDatetimeChange = (who, date) => {
    if (
      startDate &&
      who === "endDate" &&
      new Date(startDate._d + "") > new Date(date._d + "")
    ) {
      setStartDate(date);
      setEndDate(date);
    } else if (
      endDate &&
      who === "startDate" &&
      new Date(endDate._d + "") < new Date(date._d + "")
    ) {
      setStartDate(date);
      setEndDate(date);
    } else {
      if (who === "startDate") {
        setStartDate(date);
      } else {
        setEndDate(date);
      }
    }
  };

  const getClassNameReactDatetimeDays = (date) => {
    if (startDate && endDate) {
    }
    if (startDate && endDate && startDate._d + "" !== endDate._d + "") {
      if (
        new Date(endDate._d + "") > new Date(date._d + "") &&
        new Date(startDate._d + "") < new Date(date._d + "")
      ) {
        return " middle-date";
      }
      if (endDate._d + "" === date._d + "") {
        return " end-date";
      }
      if (startDate._d + "" === date._d + "") {
        return " start-date";
      }
    }
    return "";
  };

  // Handle artwork selection
  const selectReport = (report) => {
    // Update local store
    dispatch({
      type: SET_ACTIVE_REPORT,
      payload: report
    });

    // Got to review page
    history.push(`/admin/insights/reports/view/${report.query}`);
  }

  return (
    <>
      <KnowledgeHeader
        headline="Reports"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col xl="3">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Date Range</h3>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row className="input-daterange datepicker align-items-center">
                    <Col xs={12} sm={6}>
                      <label className=" form-control-label">
                        Start Date
                      </label>
                      <FormGroup>
                        <ReactDatetime
                          inputProps={{
                            placeholder: "Date Picker Here",
                          }}
                          value={startDate}
                          timeFormat={false}
                          onChange={(e) =>
                            handleReactDatetimeChange("startDate", e)
                          }
                          renderDay={(props, currentDate, selectedDate) => {
                            let classes = props.className;
                            classes +=
                              getClassNameReactDatetimeDays(currentDate);
                            return (
                              <td {...props} className={classes}>
                                {currentDate.date()}
                              </td>
                            );
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={6}>
                      <FormGroup>
                        <label className=" form-control-label">
                          End Date
                        </label>
                        <ReactDatetime
                          inputProps={{
                            placeholder: "Date Picker Here",
                          }}
                          value={endDate}
                          timeFormat={false}
                          onChange={(e) =>
                            handleReactDatetimeChange("endDate", e)
                          }
                          renderDay={(props, currentDate, selectedDate) => {
                            let classes = props.className;
                            classes +=
                              getClassNameReactDatetimeDays(currentDate);
                            return (
                              <td {...props} className={classes}>
                                {currentDate.date()}
                              </td>
                            );
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
            <ListGroup>
              { insights.filters.clients.selected && account.clients.map((client, index) => (
                <ListGroupItem
                  className={`list-group-item-action flex-column align-items-start py-4 px-4`}
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    console.log("select client")
                  }}
                  key={index}
                  active={client.id == insights.filters.clients.selected.id}
                >
                  <Row className="align-items-center">
                    <div className="col ml--2">
                      <h4 className="mb-0">
                        <a href="" onClick={(e) => e.preventDefault()} className={`${(client.id != insights.filters.clients.selected) ? 'text-white' : ''}`}>
                          {client.name}
                        </a>
                      </h4>
                    </div>
                    <Col className="col-auto">
                      { (client.id != insights.filters.clients.selected.id) &&
                        <Button color="primary" size="sm" type="button">
                          Select
                        </Button>
                      }
                    </Col>
                  </Row>
                </ListGroupItem>
              ))}
            </ListGroup>
          </Col>
          <Col xl="9">
            <Row>
              <Col xl="12">
                <Card className="bg-gradient-primary border-0">
                  <CardBody>
                    <Row>
                      <Col xl="11">
                        <Input
                          className="form-control-lg"
                          placeholder="marketing performance reports for Teddy Stratford"
                          type="text"
                        />
                      </Col>
                      <Col xl="1" style={{ paddingTop: 5 }}>
                        <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                          <i className="ni ni-bulb-61" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0"><span className="text-primary text-italic">"Marketing Performance"</span> Reports</h3>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th className="sort" data-sort="name" scope="col">
                      Name
                    </th>
                    <th className="sort" data-sort="description" scope="col">
                      Description
                    </th>
                    <th className="sort" data-sort="orders" scope="col">
                      Total Orders
                    </th>
                    <th className="sort" data-sort="sales" scope="col">
                      Total Sales
                    </th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody className="list">
                  { insights.reports.map((report, index) => (
                    <tr key={index}>
                      <th scope="row">
                        <Media className="align-items-center">
                          <Media>
                            <span className="name mb-0 text-sm">
                              {report.name}
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td className="description">{report.description}</td>
                      <td className="orders">-</td>
                      <td className="sales">-</td>
                      <td className="text-right">
                        <UncontrolledDropdown group>
                            <Button
                              color="primary" size="sm" type="button"
                              onClick={() => {
                                // Select report
                                selectReport(report);
                              }}
                              style={{
                                width: 120
                              }}
                              disabled={!report.active}
                            >
                              Explore
                            </Button>
                            <DropdownToggle
                              caret
                              color="primary"
                              disabled={!report.active}
                            />
                            <DropdownMenu>
                              <DropdownItem disabled>
                                Cancel
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <CardFooter className="py-4">

              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

// Connect to store
const ConnectedReports = connect(state => ({
  profile: state.profile,
  account: state.account,
  insights: state.insights,
  report: state.report
}))(Reports);

export default ConnectedReports;
