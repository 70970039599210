/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// node.js library that concatenates classes (strings)
import classnames from "classnames";

// javascipt plugin for creating charts
import { Chart } from "chart.js";

// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";

// Sparklines
import { Sparklines, SparklinesLine, SparklinesBars, SparklinesSpots, SparklinesNormalBand } from 'react-sparklines';

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  Media,
  NavItem,
  NavLink,
  Nav,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import KnowledgeHeader from "components/Headers/KnowledgeHeader.js";

import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";

function boxMullerRandom () {
    let phase = false,
        x1, x2, w, z;

    return (function() {

        if (phase = !phase) {
            do {
                x1 = 2.0 * Math.random() - 1.0;
                x2 = 2.0 * Math.random() - 1.0;
                w = x1 * x1 + x2 * x2;
            } while (w >= 1.0);

            w = Math.sqrt((-2.0 * Math.log(w)) / w);
            return x1 * w;
        } else {
            return x2 * w;
        }
    })();
}

// Random data generator
function randomData(n = 30) {
  return Array.apply(0, Array(n)).map(boxMullerRandom);
}

function Clients({ profile, account }) {
  console.log("Account:", account);

  const [activeNav, setActiveNav] = React.useState(1);

  const [chartExample1Data, setChartExample1Data] = React.useState("data1");

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data(chartExample1Data === "data1" ? "data2" : "data1");
  };

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  return (
    <>
      <KnowledgeHeader
        headline="Clients"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0">Light table</h3>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th className="sort" data-sort="name" scope="col">
                      Name
                    </th>
                    <th className="sort" data-sort="visitos" scope="col">
                      Total Visitors (30 Days)
                    </th>
                    <th className="sort" data-sort="sales" scope="col">
                      Total Sales (30 Days)
                    </th>
                    <th className="sort" data-sort="activity" scope="col">
                      Cognitive Activity
                    </th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody className="list">
                  { account.clients.map((client, index) => (
                    <tr key={index}>
                      <th scope="row">
                        <Media className="align-items-center">
                          <a
                            className="avatar rounded-circle mr-3 bg-white"
                            onClick={(e) => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              src={`https://logo.clearbit.com/${client.domain}`}
                            />
                          </a>
                          <Media>
                            <span className="name mb-0 text-sm">
                              {client.name}
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td className="visitors">10,000</td>
                      <td className="sales">$2,500</td>
                      <td className="activity">
                        <Sparklines data={randomData(100)} width={200}>
                          <SparklinesLine style={{ stroke: "#2991c8", fill: "none"}} />
                          <SparklinesSpots />
                          <SparklinesNormalBand style={{ fill: "#2991c8", fillOpacity: .1 }} />
                        </Sparklines>
                      </td>
                      <td className="text-right">
                        <UncontrolledDropdown group>
                            <Button onClick={() => { console.log("selected client"); }} color="primary" size="sm" type="button" style={{
                              width: 120
                            }}>
                              Explore
                            </Button>
                            <DropdownToggle
                              caret
                              color="primary"
                            />
                            <DropdownMenu>
                              <DropdownItem disabled>
                                Cancel
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem className="disabled">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className="active">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        1
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        2 <span className="sr-only">(current)</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        3
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

// Connect to store
const ConnectedClients = connect(state => ({
  profile: state.profile,
  account: state.account
}))(Clients);

export default ConnectedClients;
