/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// REact
import React from "react";
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

// Auth actions
import {
  CLEAR_MESSAGES
} from 'store/actions';

function AgentHeader({ headline, description, agent, dispatch }) {
  // Get history
  const history = useHistory();

  return (
    <>
      <div className="header bg-info pb-6">
        <span className="mask bg-gradient-info opacity-3" />

        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col lg="12" md="10">
              <h1 className="display-2 text-white">{headline}</h1>
              <p className="text-white mt-0 mb-5">
                {description}
              </p>
              <Button
                className="bg-primary"
                onClick={(e) => {
                  // Clear messages
                  dispatch({
                    type: CLEAR_MESSAGES
                  });

                  // Return to prior
                  history.goBack();
                }}
              >
                <span className="btn-inner--icon mr-2">
                  <i className="ni ni-bold-left" />
                </span>
                <span className="btn-inner--text">Return</span>
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

// Connect to store
const ConnectedAgentHeader = connect(state => ({
  profile: state.profile,
  account: state.account,
  agent: state.agent
}))(AgentHeader);

export default ConnectedAgentHeader;
