/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

// node.js library that concatenates classes (strings)
import classnames from "classnames";

// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";

// react plugin used to create DropdownMenu for selecting items
import Select2 from "react-select2-wrapper";

// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";

// Sparklines
import { Sparklines, SparklinesLine, SparklinesBars, SparklinesSpots, SparklinesNormalBand } from 'react-sparklines';

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Form,
  FormGroup,
  Label,
  Input,
  ListGroupItem,
  ListGroup,
  Media,
  NavItem,
  NavLink,
  Nav,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Spinner
} from "reactstrap";

// Formatting
import accounting from 'accounting-js';

// core components
import KnowledgeHeader from "components/Headers/KnowledgeHeader.js";

// Auth actions
import {
  INIITIALIZE_INSIGHTS,
  SET_ACTIVE_REPORT,
  SET_FILTER_TIMEFRAME
} from 'store/actions';

// Import report blocks
import MembersChart from "./components/MembersChart.js";
import MembersList from "./components/MembersList.js";

// Cube.js
import cubejs from '@cubejs-client/core';
const cubejsApi = cubejs(
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NzI3ODEwMDF9.zsDKbAU_u8oH5aqAu6kJPrYPxmkQKSeWUJynh1vwrFQ',
  { apiUrl: 'https://striking-fittstown.gcp-us-central1.cubecloudapp.dev/cubejs-api/v1' }
);

function ViewCampaign({ profile, account, knowledge, insights, report, model, dispatch }) {
  console.log("Account:", account);
  console.log("Knowledge:", knowledge);
  console.log("Insights: ", insights);
  console.log("Report:", report);

  // Get history
  const history = useHistory();

  // States (Report)
  const [startDate, setStartDate] = React.useState(report.filters.timeframe.startDate);
  const [endDate, setEndDate] = React.useState(report.filters.timeframe.endDate);
  const [activeNav, setActiveNav] = React.useState(1);
  const listRef = React.useRef(null);

  // Sync insights
  useEffect(() => {

  }, []);

  const handleReactDatetimeChange = (who, date) => {
    // Format date
    const formattedDate = date.format('YYYY-MM-DD');
    console.log("Updating date: ", who, formattedDate);

    // Set startDate
    if(who == "startDate") {
      if(startDate > endDate) {
        // Create new end date
        let newEndDate = startDate.add('days', 7).format('YYYY-MM-DD');
        setEndDate(formattedDate);
      }

      // Set start date
      setStartDate(formattedDate);
    }

    // Set startDate
    if(who == "endDate") {
      setEndDate(formattedDate);
    }

    // Update timeframe
    dispatch({
      type: SET_FILTER_TIMEFRAME,
      payload: {
        startDate: startDate,
        endDate: endDate
      }
    });
  };

  const getClassNameReactDatetimeDays = (date) => {
    if (startDate && endDate) {
    }
    if (startDate && endDate && startDate._d + "" !== endDate._d + "") {
      if (
        new Date(endDate._d + "") > new Date(date._d + "") &&
        new Date(startDate._d + "") < new Date(date._d + "")
      ) {
        return " middle-date";
      }
      if (endDate._d + "" === date._d + "") {
        return " end-date";
      }
      if (startDate._d + "" === date._d + "") {
        return " start-date";
      }
    }
    return "";
  };

  return (
    <>
      <KnowledgeHeader
        headline="Members"
        description=""
      />
      <Container className="mt--6" fluid>
        <MembersChart cubejsApi={cubejsApi} />
        <MembersList cubejsApi={cubejsApi} />
      </Container>
    </>
  );
}

// Connect to store
const ConnectedViewCampaign = connect(state => ({
  profile: state.profile,
  account: state.account,
  knowledge: state.knowledge,
  insights: state.insights,
  report: state.report,
  model: state.model
}))(ViewCampaign);

export default ConnectedViewCampaign;
