/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

// node.js library that concatenates classes (strings)
import classnames from "classnames";

// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";

// react plugin used to create DropdownMenu for selecting items
import Select2 from "react-select2-wrapper";

// javascipt plugin for creating charts
import { Chart } from "chart.js";

// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";

// Sparklines
import { Sparklines, SparklinesLine, SparklinesBars, SparklinesSpots, SparklinesNormalBand } from 'react-sparklines';

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Form,
  FormGroup,
  Label,
  Input,
  ListGroupItem,
  ListGroup,
  Media,
  NavItem,
  NavLink,
  Nav,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Spinner
} from "reactstrap";

// Formatting
import accounting from 'accounting-js';

// core components
import AgentHeader from "components/Headers/AgentHeader.js";

// Chat Components
import ChatInput from "./components/ChatInput";
import ChatMessage from "./components/ChatMessage";

// Auth actions
import {
  INIITIALIZE_INSIGHTS,
  SET_ACTIVE_REPORT
} from 'store/actions';

function Agent({ profile, account, agent, dispatch }) {
  console.log("Account:", account);
  console.log("Agent: ", agent);

  // Get history
  const history = useHistory();

  // States (Report)
  const [activeNav, setActiveNav] = React.useState(1);
  const listRef = React.useRef(null);

  // Scroll to bottom on new elements
  const messageEl = useRef(null);
  useEffect(() => {
    if (messageEl) {
      messageEl.current.addEventListener('DOMNodeInserted', event => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
      });
    }
  }, []);

  return (
    <>
      <AgentHeader
        headline="Persona Conversation"
        description=""
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col xl="12">
            <div className="col-lg-12 px-sm-0">
                <div className="card shadow-none px-0 bg-transparent mt-0 mb-4">
                    <div className="card-body px-0 pb-0">
                        <div className="px-0 pb-4">
                            <div className="row flex-row">
                                <div className="col-lg-3 mb-4">
                                    <div className="card max-height-vh-70 h-100 overflow-auto overflow-x-hidden mb-5 mb-lg-0" style={{ maxHeight: '90vh' }}>
                                        <form className="card-header">
                                          <h3>Previous Chats</h3>
                                            <div className="input-group mb-0">
                                                <input type="text" placeholder="Search" className="form-control" aria-label="Amount (to the nearest dollar)"></input>
                                                <div className="input-group-append">
                                                    <span className="input-group-text">
                                                        <i className="fa fa-search"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </form>
                                        <div className="card-body p-2">
                                            <a className="d-block p-2 rounded-lg bg-gradient-primary">
                                                <div className="d-flex p-2">
                                                    <div className="ml-2">
                                                        <div className="justify-content-between align-items-center">
                                                            <h4 className="text-white mb-0 mt-1">Persona A<span className="badge badge-success"></span>
                                                            </h4>
                                                            <p className="text-white mb-0 text-xs font-weight-normal">Business Casual</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                            <a className="d-block p-2">
                                                <div className="d-flex p-2">
                                                    <div className="ml-2">
                                                        <div className="justify-content-between align-items-center">
                                                            <h4 className="mb-0 mt-1">Persona B
                                                                <span className="badge badge-success"></span>
                                                            </h4>
                                                            <p className="mb-0 text-xs font-weight-normal text-muted">Tech-saavy Millenial</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-9">
                                    <div className="card max-height-vh-70" style={{ minHeight: 700, maxHeight: '90vh' }}>
                                        <div className="card-header shadow-xl">
                                            <div className="row">
                                                <div className="col-md-10">
                                                    <div className="d-flex align-items-center">
                                                        <div className="ms-3">
                                                            <h3 className="mb-0 d-block">Question for Persona AI</h3>
                                                            <span className="text-sm text-muted"><span className="font-weight-bold">Agent: Business Casual</span></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body overflow-auto overflow-x-hidden" ref={messageEl}>
                                          { agent.messages.map((message, index) =>
                                            <ChatMessage message={message} dispatch={dispatch} />
                                          )}
                                          { agent.processing &&
                                              <div className="row justify-content-start mb-2">
                                                  <div className="col-auto">
                                                      <Spinner />
                                                  </div>
                                              </div>
                                          }
                                        </div>
                                        <ChatInput />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

// Connect to store
const ConnectedAgent = connect(state => ({
  profile: state.profile,
  account: state.account,
  agent: state.agent
}))(Agent);

export default ConnectedAgent;

/*
<div className="col-lg-3">
    <div className="card max-height-vh-70 h-100 overflow-auto overflow-x-hidden mb-5 mb-lg-0" style={{ maxHeight: '90vh' }}>
        <div className="card-body p-2">
            <a className="d-block p-2">
            </a>
            <div className="p-2 text-center">
                <a href="javascript:;" className="d-block p-2">
                    <img alt="Image" src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxzZWFyY2h8Njh8fHBvcnRyYWl0fGVufDB8MnwwfHw%3D&amp;auto=format&amp;fit=crop&amp;w=800&amp;q=60" className="avatar avatar-lg shadow mb-0"></img>
                </a>
                <div className="ml-2 mt-0 mb-0 text-center">
                    <div className="justify-content-between align-items-center">
                        <h3 className="mb-0 mt-1">Andrei Terteci</h3>
                        <p className="mb-0 text-sm text-dark font-weight-normal">Endava</p>
                        <a href="" className="text-sm ">View profile</a>
                    </div>
                </div>
            </div>
            <hr className="my-3"/>
            <div className="mx-3">
                <h4 className="mb-0">Applied for</h4>
                <p className="text-sm text-muted font-weight-normal">Job: Krumzi</p>
            </div>
            <hr className="my-3"/>
            <div className="mx-3">
                <div className="d-flex align-items-center mb-3">
                    <i className="fa fa-envelope opacity-7"></i>
                    <div className="ml-3">
                        <h5 className="mb-0">Email</h5>
                        <span className="text-sm text-primary">user@user.com
                        </span>
                    </div>
                </div>
                <div className="d-flex align-items-center">
                    <i className="fa fa-phone opacity-7"></i>
                    <div className="ml-3">
                        <h5 className="mb-0">Phone</h5>
                        <span className="text-sm text-primary">0723922012
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

*/
