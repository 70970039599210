// React imports
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

// node.js library that concatenates classes (strings)
import classnames from "classnames";

// API (call for results)
import axios from 'axios';

// UI Imports
// import Cursor from './../ui/Cursor';

class WelcomeMessage extends React.Component {
  // UI state
  state = {
    typing: true,
    content: ""
  }

  // Constructor
  constructor(props) {
    super(props);
  }

  // On mount
  componentDidMount() {

  }

  render() {
    // Get props
    const {
      message
    } = this.props;

    // Passed in props
    const {
      typing,
      content
    } = this.state;

    return (
      <div className="row justify-content-start mb-2">
          <div className="col-auto">
              <div className="card bg-gradient-default text-black">
                  <div className="card-body p-2">
                      <p className="mb-1">
                          {message.content}
                      </p>
                  </div>
              </div>
          </div>
      </div>
    )
  }
}

export default WelcomeMessage
