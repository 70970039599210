// action - state management
import * as actionTypes from '../actions';

const initialState = {
    initialized: false,
    domain: {
      name: "Customers",
      id: "@axon/commerce/customers",
      display: "2,345 Total Customers"
    },
    entity: {
      name: "Persona 1",
      total: 100,
      value: "$2,500",
      strength: 80
    },
    factors: [
      {
        id: "@axon/commerce/customers/age",
        name: "Fit and Comfort",
        value: 90,
        display: "Fit and Comfort",
        summary: "Fit Quality",
        details: "As a business casual buyer, a well-fitted, comfortable shirt is essential. Teddy Stratford's athletic fit shirts are designed for a closer fit, making them suitable for both formal and casual occasions."
      },
      {
        id: "@axon/commerce/customers/age",
        name: "Material Quality",
        value: 85,
        display: "Material Quality",
        summary: "Fabric Durability",
        details: "High-quality materials translate into durability and longevity. Being a professional, I appreciate shirts that remain pristine even after multiple wears and washes."
      },
      {
        id: "@axon/commerce/customers/age",
        name: "Versatility",
        value: 80,
        display: "Versatility",
        summary: "Styling Ease",
        details: "I prefer shirts that can transition seamlessly from the office to a night out. I value clothing that could be paired with other items in my existing wardrobe and can be worn in various scenarios."
      },
      {
        id: "@axon/commerce/customers/age",
        name: "Convenience",
        value: 70,
        display: "Convenience",
        summary: "Easy Maintenance",
        details: "As a busy professional, I favour shirts that require minimal maintenance. This means shirts that are easy to care for, perhaps requiring no to little ironing or special laundry care."
      },
      {
        id: "@axon/commerce/customers/age",
        name: "Brand Ethos",
        value: 60,
        display: "Brand Ethos",
        summary: "Business Values",
        details: "The brand's ethos and their stance on various issues such as sustainability, ethical manufacturing and fair trade practices would also influence my purchasing decision. I want to align my purchases with my personal values."
      }
    ],
    stats: [
      {
        id: "@axon/commerce/customers/age",
        name: "$100 - $200",
        value: 15,
        display: "LTV: $100 - $200"
      },
      {
        id: "@axon/commerce/customers/age",
        name: "$200 - $300",
        value: 30,
        display: "LTV: $200 - $300"
      },
      {
        id: "@axon/commerce/customers/age",
        name: "$300 - $500",
        value: 45,
        display: "LTV: $300 - $500"
      },
      {
        id: "@axon/commerce/customers/age",
        name: "$500 - $800",
        value: 10,
        display: "LTV: $500 - $800"
      },
      {
        id: "@axon/commerce/customers/age",
        name: "$800+",
        value: 5,
        display: "LTV: $800+"
      }
    ],
    items: [
      {
        name: "Rami Nabi",
        gender: "Male",
        age: "42",
        value: "$1,432.23",
        days_to_purchase: "2 Days",
        education: "UC Santa Barbara (American History), Chapman University (Real Estate)",
        industry: "Law Practice",
        jobTitle: "Attorney",
        jobFunction: "Lawyer",
        interests: "cooking, exercise, electronics, outdoors, investing, politics, home improvement, legal research, reading, crafts, gourmet cooking, sports, music, travel, movies, home decoration, film"
      },
      {
        name: "Will Tull",
        gender: "Male",
        age: "48",
        value: "$983.12",
        days_to_purchase: "1 Day",
        education: "United States Naval Academy (Engineering)",
        industry: "Medical Devices",
        jobTitle: "Director of Project Management",
        jobFunction: "Project Management",
        interests: ""
      },
      {
        name: "Peter Carrillo",
        gender: "Male",
        age: "40",
        value: "$687.30",
        days_to_purchase: "1 Day",
        education: "University of Nevada - Las Vegas (Electronics Engineering)",
        industry: "Food Production",
        jobTitle: "Senior Vice President of Development",
        jobFunction: "",
        interests: "south park, jaja woods, magazine, coola, winery/vineyard, jay patton, hyde beach miami, having a good time, chinese restaurant, drink, public figure, sundaze, media/news company, banger brewing, public school"
      },
      {
        name: "Jon Hasson",
        gender: "Male",
        age: "47",
        value: "$459.35",
        days_to_purchase: "3 Days",
        education: "",
        industry: "Insurance",
        jobTitle: "Chief Technology Officer",
        jobFunction: "",
        interests: ""
      },
      {
        name: "Andrew Gharakhanian",
        gender: "Male",
        age: "46",
        value: "$904.20",
        days_to_purchase: "2 Days",
        education: "University of Michigan (Economics), Georgetown University Law Center",
        industry: "Legal Services",
        jobTitle: "Partner",
        jobFunction: "",
        interests: ""
      },
      {
        name: "William Grace",
        gender: "Male",
        age: "",
        value: "$1,424.40",
        days_to_purchase: "2 Days",
        education: "Fordham University School of Law (Law), Fordham University (English)",
        industry: "Law Practice",
        jobTitle: "Attorney",
        jobFunction: "Lawyer",
        interests: ""
      },
      {
        name: "John Safanda",
        gender: "Male",
        age: "53",
        value: "$428.49",
        days_to_purchase: "1 Day",
        education: "",
        industry: "Medical Practice",
        jobTitle: "Orthopedic Surgeon",
        jobFunction: "Doctor",
        interests: ""
      },
      {
        name: "Mitchell Rothberg",
        gender: "Male",
        age: "43",
        value: "$834.23",
        days_to_purchase: "2 Days",
        education: "Rockland Community College (Business Admnistration)",
        industry: "Construction",
        jobTitle: "Senior Superintendent",
        jobFunction: "",
        interests: ""
      }
    ]
};

// ===========================|| PRODUCTS REDUCER ||=========================== //

const modelReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INIITIALIZE_MODEL: {
          console.log("Initializing model...", action.payload);

          // Update state
          return {
              ...state,
              initialized: true
          };
        }
        case actionTypes.SET_ACTIVE_ENTITY: {
          console.log("Setting active entity...", action.payload);

          // Update state
          return {
              ...state,
              entity: action.payload
          };
        }
        default:
            return state;
    }
};

export default modelReducer;



/*

stats: [
  {
    id: "@axon/commerce/customers/age",
    name: "$100 - $200",
    value: 15,
    display: "LTV: $100 - $200"
  },
  {
    id: "@axon/commerce/customers/age",
    name: "$200 - $300",
    value: 30,
    display: "LTV: $200 - $300"
  },
  {
    id: "@axon/commerce/customers/age",
    name: "$300 - $500",
    value: 45,
    display: "LTV: $300 - $500"
  },
  {
    id: "@axon/commerce/customers/age",
    name: "$500 - $800",
    value: 10,
    display: "LTV: $500 - $800"
  },
  {
    id: "@axon/commerce/customers/age",
    name: "$800+",
    value: 5,
    display: "LTV: $800+"
  }
],

*/
