/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/

// React
import React, { Component } from 'react';
import { connect } from 'react-redux';

// nodejs library to set properties for components
import PropTypes from "prop-types";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Form,
  FormGroup,
  Label,
  Input,
  ListGroupItem,
  ListGroup,
  Media,
  NavItem,
  NavLink,
  Nav,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Spinner
} from "reactstrap";

// Account config
import config from "config";

// CubeJs
import cubejs from '@cubejs-client/core';
import { QueryRenderer } from '@cubejs-client/react';

// Pre-ready queries
function generateQuery(report, filters) {
  return {
    "measures": [
      "Members.totalMembers"
    ],
    "order": {},
    "timeDimensions": [
      {
        "dimension": "Members.createdAt",
        "dateRange": "Last 7 days"
      }
    ]
  };
}

function render() {
  // Wrapper for query for all data
  function renderFromQuery({ resultSet, error }) {
    // Format data
    let data = null

    // Parse result set
    if(resultSet && resultSet.loadResponse.results.length > 0) {
      // Get first row results
      //const results = resultSet.loadResponse.results[0].data[0];

      // Parse into data
      data = resultSet.loadResponse.results[0].data[0]['Members.totalMembers'];

      // // Populate data
      // for(var i = 0; i < results.length; i++) {
      //   // Add to data
      //   data.push({
      //     name: `${results[i]['Members.firstName']} ${results[i]['Members.lastName']}`,
      //     city: results[i]['Members.city'],
      //     state: results[i]['Members.state'],
      //     dateJoined: results[i]['Members.createdDate.day']
      //   })
      // }
    }
    console.log("Member Growth - KPI - Total Members: ", data, resultSet);

    // Return formatted table
    return (
      <>
        { !data ?
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          :
          <Col md="12" xl="6">
            <Card className={`card-stats text-white bg-white`}>
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className={`text-uppercase text-muted mb-0 text-primary`}
                    >
                      New Members (Last 7 Days)
                    </CardTitle>
                    <span className={`h2 font-weight-bold mb-0`}>{data}</span>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
        }
      </>
    );
  }

  // Return rener query
  return renderFromQuery;
}

// Report Table Component
class MemberGrowthNewKPIs extends Component {

  // On mount
  constructor(props) {
    super(props);
  }

  // Render
  render() {
    // Get props
    const { filters, index, cubejsApi } = this.props;

    // result
    return (
      <QueryRenderer query={generateQuery(filters)}
                     cubejsApi={cubejsApi}
                     filters={filters}
                     render={render()} />
    )
  }
}

// Connect to store
const ConnectedMemberGrowthNewKPIs = connect(state => ({
  profile: state.profile,
  filters: state.report.filters
}))(MemberGrowthNewKPIs);

export default ConnectedMemberGrowthNewKPIs;
