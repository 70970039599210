/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// React
import React, { Component } from 'react';
import { connect } from 'react-redux';

// nodejs library to set properties for components
import PropTypes from "prop-types";

// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Spinner
} from "reactstrap";

// Formatting
import accounting from 'accounting-js';

// CubeJs
import cubejs from '@cubejs-client/core';
import { QueryRenderer } from '@cubejs-client/react';

// Pre-ready queries
function generateQuery(report, filters) {
  return {
    "measures": [
      "MarketingPerformanceDaily.totalOrders",
      "MarketingPerformanceDaily.totalSales",
      "MarketingPerformanceDaily.totalSpend",
      "MarketingPerformanceDaily.returnOnAdSpend",
      "MarketingPerformanceDaily.daysToPurchase"
    ],
    "timeDimensions": [
      {
        "dimension": "MarketingPerformanceDaily.orderDate",
        "dateRange": [
          filters.timeframe.startDate,
          filters.timeframe.endDate
        ]
      }
    ]
  };
}

// Closure for render
function render(name, index) {
  // Wrapper for query for all data
  function renderFromQuery({ resultSet, error }) {
    console.log("-> Results: ", resultSet);

    // Format data
    let data = [];

    // Parse result set
    if(resultSet && resultSet.loadResponse.results.length > 0) {
      // Get first row results
      const results = resultSet.loadResponse.results[0].data[0];

      // Udpate data
      data = [
        {
          name: "Return on Ad Spend",
          key: "MarketingPerformanceDaily.returnOnAdSpend",
          value: results['MarketingPerformanceDaily.returnOnAdSpend'] ? results['MarketingPerformanceDaily.returnOnAdSpend'].toFixed(2) : ""
        },
        {
          name: "Total Sales",
          key: "MarketingPerformanceDaily.totalSales",
          value: results['MarketingPerformanceDaily.totalSales'] ? accounting.formatMoney(results['MarketingPerformanceDaily.totalSales']) : ""
        },
        {
          name: "Total Orders",
          key: "MarketingPerformanceDaily.totalOrders",
          value: results['MarketingPerformanceDaily.totalOrders'] ? results['MarketingPerformanceDaily.totalOrders'] : ""
        },
        {
          name: "Days to Purchase",
          key: "MarketingPerformanceDaily.daysToPurchase",
          value: results['MarketingPerformanceDaily.daysToPurchase'] ? results['MarketingPerformanceDaily.daysToPurchase'].toFixed(2) : ""
        }
      ];
    }
    console.log("Data: ", data);

    // Return formatted table
    return (
      <>
        { data.length == 0 ?
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          :
          <Row key={index}>
            { data.map((kpi, idx) => (
              <Col md="12" xl={12 / data.length} key={idx}>
                <Card className={`card-stats ${(kpi.highlight) ? 'text-white bg-primary' : ''}`}>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className={`text-uppercase text-muted mb-0 ${(kpi.highlight) ? 'text-white' : ''}`}
                        >
                          { kpi.name }
                        </CardTitle>
                        <span className={`h2 font-weight-bold mb-0 ${(kpi.highlight) ? 'text-white' : ''}`}>{ kpi.value }</span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        }
      </>
    );
  }

  // Return rener query
  return renderFromQuery;
}

// Report Table Component
class KPI extends Component {

  // On mount
  constructor(props) {
    super(props);
  }

  // Render
  render() {
    // Get props
    const { report, filters, index, cubejsApi } = this.props;
    console.log("KPI: ", this.props);

    // result
    return (
      <>
        { report &&
          <QueryRenderer query={generateQuery(report, filters)}
                         cubejsApi={cubejsApi}
                         report={report}
                         filters={filters}
                         render={render(report.name, index)} />
        }
      </>
    )
  }
}

// Connect to store
const ConnectedKPI = connect(state => ({
  profile: state.profile,
  filters: state.report.filters
}))(KPI);

export default ConnectedKPI;



// function KPI({ report, index, cubejsApi }) {
//   // Parse report data
//   const { name, data } = report;
//
//   return (
//     <Row key={index}>
//       { data.map((kpi, idx) => (
//         <Col md="12" xl={12 / data.length} key={idx}>
//           <Card className={`card-stats ${(kpi.highlight) ? 'text-white bg-primary' : ''}`}>
//             <CardBody>
//               <Row>
//                 <div className="col">
//                   <CardTitle
//                     tag="h5"
//                     className={`text-uppercase text-muted mb-0 ${(kpi.highlight) ? 'text-white' : ''}`}
//                   >
//                     { kpi.name }
//                   </CardTitle>
//                   <span className={`h2 font-weight-bold mb-0 ${(kpi.highlight) ? 'text-white' : ''}`}>{ kpi.value }</span>
//                 </div>
//               </Row>
//             </CardBody>
//           </Card>
//         </Col>
//       ))}
//     </Row>
//   );
// }

//export default KPI;
